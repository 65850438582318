import React, { useEffect, useState } from 'react'
import { Box, Link, Typography } from '@mui/material'

interface CountdownTimerProps {
  onResend: () => void // Callback for when "Resend here" is clicked
  doStart: boolean // Flag to start/reset the timer
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({ onResend, doStart = false }) => {
  const [secondsLeft, setSecondsLeft] = useState<number>(() => {
    const storedTime = localStorage.getItem('resendCountdown')
    const savedTime = storedTime ? parseInt(storedTime, 10) : 0
    const now = Math.floor(Date.now() / 1000)
    return Math.max(0, savedTime - now)
  })

  useEffect(() => {
    if (doStart) {
      const newTime = Math.floor(Date.now() / 1000) + 60
      localStorage.setItem('resendCountdown', newTime.toString())
      setSecondsLeft(60) // Start or reset the timer
    }
  }, [doStart]) // Trigger effect whenever `doStart` changes

  useEffect(() => {
    if (secondsLeft === 0) {
      localStorage.removeItem('resendCountdown')
      return // Stop the timer if it hits 0
    }

    const interval = setInterval(() => {
      setSecondsLeft((prev) => {
        if (prev <= 1) {
          clearInterval(interval)
          localStorage.removeItem('resendCountdown')
          return 0
        }
        return prev - 1
      })
    }, 1000)

    return () => {
      clearInterval(interval) // Cleanup interval on unmount
    }
  }, [secondsLeft])

  const handleResendClick = () => {
    const newTime = Math.floor(Date.now() / 1000) + 60
    localStorage.setItem('resendCountdown', newTime.toString())
    setSecondsLeft(60) // Restart timer
    onResend() // Trigger the resend action
  }

  return (
    <Box display="flex" alignItems="center" paddingTop="10px">
      <Typography>{"Haven't received the code?"}</Typography>
      {secondsLeft === 0 ? (
        <Link
          onClick={handleResendClick}
          style={{
            marginLeft: '5px',
            cursor: 'pointer',
            color: '#1976d2',
            fontFamily: 'calibri',
          }}
        >
          Resend here
        </Link>
      ) : (
        <Typography style={{ marginLeft: '5px', color: '#888' }}>
          Resend in {secondsLeft} seconds
        </Typography>
      )}
    </Box>
  )
}

export default CountdownTimer
