import React from 'react'
import { Button, styled } from '@mui/material'
import type { ButtonProps } from '@mui/material/Button'

const HiddenInput = styled('input')(() => ({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
}))

interface UploaderProps extends ButtonProps {
  placeholder?: string
  multiple?: boolean
  onFileSelect?: (event: React.ChangeEvent<HTMLInputElement>) => void
  required?: boolean
  accept?: string
}

const Uploader: React.FC<UploaderProps> = (props) => {
  return (
    <Button component="label" {...props}>
      {props.placeholder}
      <HiddenInput
        onChange={props.onFileSelect}
        type="file"
        multiple={props.multiple}
        required={props.required}
        accept={props.accept}
      />
    </Button>
  )
}

export default Uploader
