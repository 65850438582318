import React, { useState } from 'react'
import { Box, FormControl, FormLabel, TextField } from '@mui/material'
import { useAppDispatch, useAppSelect } from 'src/hooks/useRedux'

import { setReportPayload } from '../../ducks/report.reducer'

const UKPhoneNumberRegex = /^(\+44\s?7\d{3}|\(?07\d{3}\)?)\s?\d{3}\s?\d{3}$/

const ReporterPhoneNumber: React.FC = () => {
  const dispatch = useAppDispatch()
  const { payload, loading } = useAppSelect((state) => state.reports)
  const [error, setError] = useState('')

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const phoneNumber = e.target.value
    if (!UKPhoneNumberRegex.test(phoneNumber)) {
      setError('Please enter a valid telephone number.')
    } else {
      setError('')
    }
    dispatch(setReportPayload({ ...payload, reporterPhoneNumber: phoneNumber }))
  }

  return (
    <FormControl sx={{ width: '100%' }}>
      <FormLabel>
        Telephone No.{' '}
        <Box component="span" sx={{ color: 'bw.red' }}>
          *
        </Box>
      </FormLabel>

      <TextField
        value={payload.reporterPhoneNumber}
        onChange={onChange}
        disabled={loading}
        placeholder="Enter Telephone *"
        required
        error={!!error}
        helperText={error}
      />
    </FormControl>
  )
}

export default ReporterPhoneNumber
