import React from 'react'
import { SvgIcon } from '@mui/material'

import { type SvgIconType } from './SvgIconType'

const PatternIcon: SvgIconType = (props) => {
  return (
    <SvgIcon viewBox="0 0 379 754" fill="none" {...props}>
      <g clipPath="url(#clip0_198_37551)">
        <mask
          id="mask0_198_37551"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="-323"
          y="0"
          width="702"
          height="754"
        >
          <path d="M-323 0H379V754H-323V0Z" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_198_37551)">
          <mask
            id="mask1_198_37551"
            style={{ maskType: 'luminance' }}
            maskUnits="userSpaceOnUse"
            x="-541"
            y="-195"
            width="1116"
            height="1056"
          >
            <path
              d="M200.218 -194.549L-540.583 343.751L-166.369 860.056L574.432 321.756L200.218 -194.549Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask1_198_37551)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M-3.42075 284.779L32.5774 297.61L38.6258 336.647L2.13438 322.877L-3.42075 284.779ZM-2.32029 286.123L2.94472 322.225L37.5057 335.266L31.7742 298.275L-2.32029 286.123Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M-20.2338 339.066L-25.7988 301.034L-26.6852 301.164L-21.0424 339.719L15.9111 353.623L16.2263 352.784L-20.2338 339.066Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M-20.5759 338.976L2.24357 322.395L2.76918 323.12L-20.0503 339.702L-20.5759 338.976Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.1464 352.964L38.2363 336.186L38.7619 336.911L15.6729 353.689L15.1464 352.964Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.0807 230.222L49.0779 243.053L55.13 282.087L18.6358 268.32L13.0807 230.222ZM14.1812 231.566L19.4453 267.667L54.0098 280.707L48.2756 243.718L14.1812 231.566Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M-3.7288 284.506L-9.29733 246.476L-10.1838 246.606L-4.53824 285.16L32.4179 299.066L32.7322 298.227L-3.7288 284.506Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M-4.07169 284.415L18.7451 267.836L19.2707 268.562L-3.5452 285.142L-4.07169 284.415Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M31.6515 298.404L54.7405 281.626L55.2669 282.352L32.1771 299.13L31.6515 298.404Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M-10.2895 246.862L12.9473 229.977L13.4729 230.702L-9.76385 247.588L-10.2895 246.862Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M-28.4519 178.522L7.54529 191.353L13.5938 230.39L-22.8968 216.62L-28.4519 178.522ZM-27.3514 179.866L-22.0873 215.967L12.4736 229.009L6.74301 192.019L-27.3514 179.866Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M-9.88116 246.704L13.2078 229.927L13.7343 230.652L-9.35555 247.43L-9.88116 246.704Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M-11.9504 123.964L24.0504 136.793L30.0989 175.83L-6.39529 162.062L-11.9504 123.964ZM-10.8499 125.308L-5.58585 161.41L28.9787 174.449L23.2472 137.458L-10.8499 125.308Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M-28.76 178.249L-34.3258 140.217L-35.2114 140.347L-29.5694 178.902L7.38589 192.809L7.70107 191.969L-28.76 178.249Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.62033 192.146L29.7093 175.368L30.2358 176.094L7.14594 192.872L6.62033 192.146Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M-37.9656 18.2148L-1.96754 31.0462L4.08363 70.0806L-32.4105 56.313L-37.9656 18.2148ZM-36.8652 19.559L-31.6011 55.6604L2.96346 68.6999L-2.77072 31.7117L-36.8652 19.559Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M-19.3976 86.3947L3.69233 69.6167L4.21794 70.3424L-18.8711 87.1203L-19.3976 86.3947Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.02008 69.7915L40.0173 82.6229L46.0711 121.661L9.5752 107.89L4.02008 69.7915ZM5.12054 71.1356L10.3846 107.237L44.951 120.28L39.215 83.2884L5.12054 71.1356Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M-12.7894 124.076L-18.3553 86.0444L-19.2408 86.1744L-13.5989 124.73L23.3573 138.636L23.6716 137.797L-12.7894 124.076Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M-13.1324 123.986L9.68714 107.405L10.2136 108.13L-12.6058 124.712L-13.1324 123.986Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M22.5908 137.975L45.6798 121.197L46.2063 121.922L23.1164 138.7L22.5908 137.975Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M-19.3483 86.435L3.88933 69.5498L4.41494 70.2754L-18.8227 87.1606L-19.3483 86.435Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M46.0863 121.104L82.0844 133.935L88.1347 172.975L51.6415 159.202L46.0863 121.104ZM47.1868 122.447L52.4518 158.55L87.0145 171.595L81.2812 134.6L47.1868 122.447Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M29.2769 175.389L23.7083 137.359L22.8219 137.489L28.4674 176.042L65.4236 189.949L65.7388 189.11L29.2769 175.389Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M28.9312 175.3L51.7507 158.718L52.2763 159.444L29.4568 176.026L28.9312 175.3Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M64.6571 189.286L87.747 172.508L88.2726 173.233L65.1827 190.011L64.6571 189.286Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M22.718 137.747L45.9521 120.864L46.4786 121.589L23.2445 138.472L22.718 137.747Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M62.5896 66.5488L98.585 79.377L104.64 118.415L68.1429 104.644L62.5896 66.5488ZM63.6909 67.8928L68.9524 103.991L103.519 117.034L97.7827 80.0425L63.6909 67.8928Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M45.7801 120.833L40.2124 82.7983L39.3269 82.9283L44.9706 121.487L81.925 135.391L82.2393 134.552L45.7801 120.833Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M45.4354 120.74L68.2549 104.159L68.7814 104.884L45.9619 121.466L45.4354 120.74Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M81.1586 134.728L104.248 117.95L104.774 118.676L81.6842 135.454L81.1586 134.728Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M39.2194 83.1894L62.4571 66.3042L62.9827 67.0298L39.7459 83.915L39.2194 83.1894Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M31.1125 175.156L67.1105 187.988L73.159 227.024L36.6685 213.254L31.1125 175.156ZM32.2138 176.5L37.4779 212.602L72.0389 225.644L66.3074 188.653L32.2138 176.5Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14.3029 229.441L8.73439 191.411L7.84882 191.541L13.4944 230.095L50.447 244.004L50.7621 243.165L14.3029 229.441Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.9582 229.353L36.7777 212.771L37.3033 213.497L14.4838 230.079L13.9582 229.353Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M49.6832 243.339L72.7731 226.561L73.2987 227.286L50.2097 244.064L49.6832 243.339Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.73959 191.799L30.9764 174.915L31.5028 175.64L8.2652 192.526L7.73959 191.799Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M73.176 226.471L109.174 239.302L115.228 278.34L78.7311 264.569L73.176 226.471ZM74.2774 227.815L79.5415 263.916L114.108 276.958L108.371 239.967L74.2774 227.815Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M56.3665 280.755L50.8016 242.723L49.9151 242.853L55.5571 281.408L92.5133 295.315L92.8285 294.476L56.3665 280.755Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M56.0245 280.664L78.8439 264.083L79.3695 264.809L56.5501 281.39L56.0245 280.664Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M91.7468 294.652L114.837 277.875L115.362 278.6L92.2724 295.378L91.7468 294.652Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M49.8058 243.11L73.0426 226.226L73.5691 226.951L50.3315 243.836L49.8058 243.11Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M89.6802 171.91L125.678 184.742L131.729 223.781L95.2362 210.008L89.6802 171.91ZM90.7815 173.254L96.0456 209.356L130.609 222.4L124.875 185.407L90.7815 173.254Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M72.8681 226.197L67.3022 188.166L66.4166 188.296L72.0586 226.851L109.015 240.757L109.33 239.918L72.8681 226.197Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M72.526 226.107L95.3455 209.525L95.8711 210.251L73.0516 226.833L72.526 226.107Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M108.248 240.094L131.338 223.316L131.864 224.043L108.774 240.821L108.248 240.094Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M66.3091 188.556L89.5468 171.671L90.0724 172.396L66.8357 189.282L66.3091 188.556Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M131.669 223.49L167.667 236.321L173.714 275.358L137.224 261.589L131.669 223.49ZM132.769 224.835L138.033 260.936L172.595 273.977L166.863 236.987L132.769 224.835Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M114.855 277.778L109.291 239.745L108.404 239.875L114.046 278.43L151 292.334L151.315 291.495L114.855 277.778Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M114.513 277.687L137.333 261.105L137.859 261.831L115.039 278.412L114.513 277.687Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M150.236 291.675L173.326 274.897L173.851 275.623L150.762 292.4L150.236 291.675Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M108.295 240.133L131.532 223.248L132.058 223.974L108.821 240.859L108.295 240.133Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M173.732 274.805L209.729 287.636L215.781 326.67L179.287 312.903L173.732 274.805ZM174.833 276.149L180.097 312.25L214.661 325.29L208.927 288.301L174.833 276.149Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M156.923 329.089L151.354 291.059L150.468 291.189L156.113 329.743L193.069 343.649L193.384 342.81L156.923 329.089Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M156.58 328.998L179.397 312.419L179.922 313.145L157.106 329.725L156.58 328.998Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M192.303 342.986L215.392 326.209L215.918 326.934L192.828 343.712L192.303 342.986Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M150.361 291.444L173.599 274.56L174.124 275.285L150.888 292.171L150.361 291.444Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M190.233 220.247L226.234 233.075L232.283 272.112L195.789 258.345L190.233 220.247ZM191.334 221.591L196.598 257.692L231.163 270.731L225.431 233.741L191.334 221.591Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M173.423 274.531L167.858 236.5L166.972 236.63L172.614 275.185L209.57 289.091L209.885 288.252L173.423 274.531Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M173.081 274.441L195.901 257.859L196.426 258.585L173.607 275.167L173.081 274.441Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M208.803 288.429L231.893 271.651L232.419 272.377L209.33 289.155L208.803 288.429Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M166.863 236.887L190.1 220.002L190.626 220.727L167.389 237.613L166.863 236.887Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M105.653 117.741L141.649 130.569L147.7 169.609L111.207 155.836L105.653 117.741ZM106.754 119.085L112.016 155.183L146.58 168.228L140.846 131.234L106.754 119.085Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M88.8385 172.025L83.2727 133.993L82.3871 134.123L88.029 172.678L124.985 186.585L125.3 185.746L88.8385 172.025Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M88.4964 171.934L111.316 155.353L111.842 156.079L89.022 172.66L88.4964 171.934Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M124.219 185.922L147.309 169.145L147.834 169.87L124.744 186.648L124.219 185.922Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M82.2797 134.384L105.517 117.499L106.043 118.224L82.8062 135.109L82.2797 134.384Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M147.717 169.056L183.712 181.884L189.766 220.921L153.27 207.15L147.717 169.056ZM148.817 170.4L154.08 206.497L188.646 219.54L182.909 182.549L148.817 170.4Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M130.907 223.34L125.336 185.307L124.451 185.437L130.098 223.993L167.051 237.897L167.367 237.058L130.907 223.34Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M130.559 223.248L153.379 206.667L153.905 207.393L131.086 223.974L130.559 223.248Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M166.285 237.235L189.375 220.457L189.901 221.182L166.812 237.96L166.285 237.235Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M124.347 185.695L147.584 168.811L148.11 169.536L124.872 186.421L124.347 185.695Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M164.218 114.498L200.219 127.326L206.268 166.363L169.774 152.596L164.218 114.498ZM165.319 115.841L170.583 151.943L205.148 164.982L199.416 127.992L165.319 115.841Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M147.409 168.782L141.841 130.747L140.955 130.877L146.6 169.435L183.553 183.339L183.868 182.5L147.409 168.782Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M147.064 168.689L169.884 152.107L170.409 152.832L147.59 169.414L147.064 168.689Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M182.787 182.677L205.876 165.898L206.402 166.624L183.312 183.402L182.787 182.677Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M140.847 131.138L164.085 114.253L164.611 114.978L141.374 131.863L140.847 131.138Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M206.203 166.074L242.205 178.903L248.255 217.943L211.759 204.172L206.203 166.074ZM207.305 167.418L212.569 203.52L247.135 216.563L241.402 179.569L207.305 167.418Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M189.394 220.359L183.829 182.327L182.942 182.457L188.585 221.012L225.54 234.919L225.856 234.08L189.394 220.359Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M189.052 220.269L211.871 203.688L212.397 204.413L189.577 220.995L189.052 220.269Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M224.774 234.257L247.864 217.479L248.39 218.204L225.3 234.982L224.774 234.257Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M182.836 182.718L206.073 165.833L206.599 166.558L183.361 183.444L182.836 182.718Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M248.27 217.386L284.268 230.217L290.319 269.258L253.825 255.484L248.27 217.386ZM249.371 218.731L254.635 254.832L289.199 267.877L283.465 230.883L249.371 218.731Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M231.461 271.671L225.892 233.641L225.006 233.771L230.652 272.324L267.608 286.231L267.922 285.392L231.461 271.671Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M231.115 271.582L253.935 255L254.46 255.727L231.641 272.308L231.115 271.582Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M266.841 285.569L289.93 268.791L290.457 269.516L267.367 286.294L266.841 285.569Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M224.902 234.029L248.136 217.146L248.662 217.873L225.428 234.756L224.902 234.029Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M264.774 162.832L300.769 175.659L306.823 214.697L270.327 200.926L264.774 162.832ZM265.874 164.175L271.136 200.273L305.703 213.316L299.966 176.325L265.874 164.175Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M247.964 217.116L242.397 179.081L241.51 179.211L247.155 217.769L284.108 231.673L284.423 230.834L247.964 217.116Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M247.619 217.023L270.439 200.441L270.965 201.166L248.146 217.748L247.619 217.023Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M283.342 231.011L306.432 214.233L306.957 214.958L283.868 231.736L283.342 231.011Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M241.404 179.472L264.64 162.587L265.167 163.312L241.929 180.198L241.404 179.472Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M80.6222 11.4839L116.618 24.312L122.669 63.352L86.1755 49.5788L80.6222 11.4839ZM81.7227 12.8278L86.985 48.9263L121.549 61.9711L115.814 24.9775L81.7227 12.8278Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M63.8127 65.7687L58.2415 27.7358L57.356 27.8659L63.0033 66.422L99.9568 80.3258L100.272 79.4865L63.8127 65.7687Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M63.4644 65.6774L86.2839 49.0957L86.8104 49.8213L63.9909 66.403L63.4644 65.6774Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M99.1903 79.6632L122.28 62.8853L122.806 63.6108L99.7168 80.3888L99.1903 79.6632Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M57.2521 28.1241L80.4862 11.2412L81.0118 11.9668L57.7777 28.8498L57.2521 28.1241Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M122.686 62.7974L158.687 75.6264L164.735 114.663L128.241 100.896L122.686 62.7974ZM123.786 64.1413L129.05 100.243L163.615 113.283L157.883 76.2918L123.786 64.1413Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M105.876 117.082L100.309 79.0469L99.4223 79.177L105.067 117.736L142.02 131.639L142.336 130.8L105.876 117.082Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M105.532 116.989L128.351 100.407L128.877 101.133L106.057 117.714L105.532 116.989Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M141.254 130.977L164.344 114.199L164.869 114.925L141.78 131.703L141.254 130.977Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M99.3148 79.4379L122.552 62.5527L123.078 63.2783L99.8413 80.1635L99.3148 79.4379Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M139.19 8.2373L175.188 21.0686L181.237 60.1054L144.745 46.3354L139.19 8.2373ZM140.29 9.58137L145.555 45.6828L180.116 58.7246L174.385 21.7341L140.29 9.58137Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M122.38 62.5221L116.809 24.4893L115.924 24.6193L121.571 63.1753L158.522 77.0813L158.837 76.2422L122.38 62.5221Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M122.033 62.4308L144.853 45.8491L145.378 46.5747L122.559 63.1564L122.033 62.4308Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M157.758 76.4167L180.848 59.6387L181.374 60.3643L158.285 77.1422L157.758 76.4167Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M115.816 24.8804L139.054 7.99512L139.579 8.72072L116.343 25.6059L115.816 24.8804Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M181.176 59.8145L217.173 72.6458L223.224 111.686L186.731 97.9121L181.176 59.8145ZM182.276 61.1585L187.54 97.2603L222.104 110.305L216.371 73.3112L182.276 61.1585Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M164.366 114.099L158.798 76.0693L157.911 76.1994L163.557 114.752L200.509 128.661L200.825 127.822L164.366 114.099Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M164.021 114.011L186.84 97.4297L187.366 98.155L164.546 114.737L164.021 114.011Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M199.746 127.997L222.835 111.219L223.362 111.945L200.272 128.722L199.746 127.997Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M157.805 76.4604L181.041 59.5752L181.567 60.3008L158.33 77.186L157.805 76.4604Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M223.241 111.132L259.236 123.96L265.29 162.997L228.794 149.226L223.241 111.132ZM224.341 112.476L229.604 148.574L264.17 161.617L258.433 124.625L224.341 112.476Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M206.43 165.413L200.864 127.381L199.977 127.511L205.62 166.066L242.576 179.973L242.891 179.134L206.43 165.413Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M206.087 165.323L228.906 148.741L229.433 149.467L206.613 166.048L206.087 165.323Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M241.809 179.311L264.899 162.533L265.425 163.259L242.336 180.037L241.809 179.311Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M199.871 127.773L223.108 110.887L223.634 111.613L200.396 128.498L199.871 127.773Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M239.745 56.5713L275.743 69.4027L281.792 108.439L245.301 94.6692L239.745 56.5713ZM240.847 57.9154L246.111 94.0165L280.672 107.059L274.94 70.0682L240.847 57.9154Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M222.933 110.858L217.365 72.8232L216.479 72.9532L222.124 111.512L259.077 125.416L259.392 124.576L222.933 110.858Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M222.588 110.765L245.408 94.1836L245.933 94.9089L223.114 111.491L222.588 110.765Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M258.311 124.753L281.401 107.975L281.926 108.7L258.837 125.478L258.311 124.753Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M216.372 73.2143L239.609 56.3291L240.136 57.0547L216.898 73.9399L216.372 73.2143Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M173.729 22.2453L196.819 5.46729L197.344 6.19289L174.255 22.9709L173.729 22.2453Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M197.224 5.37988L233.221 18.2113L239.273 57.2456L202.779 43.478L197.224 5.37988ZM198.325 6.72397L203.589 42.8254L238.153 55.8649L232.419 18.8767L198.325 6.72397Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M180.415 59.6643L174.849 21.6323L173.963 21.7623L179.605 60.3175L216.559 74.2213L216.874 73.3821L180.415 59.6643Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M180.072 59.5743L202.891 42.9927L203.418 43.7183L180.598 60.3L180.072 59.5743Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M215.795 73.5622L238.884 56.7842L239.41 57.5098L216.32 74.2877L215.795 73.5622Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M173.854 22.0199L197.091 5.13477L197.616 5.86037L174.38 22.7456L173.854 22.0199Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M232.296 19.0041L255.385 2.22607L255.912 2.95169L232.822 19.7297L232.296 19.0041Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M255.716 2.39941L291.714 15.2308L297.762 54.2675L261.272 40.4976L255.716 2.39941ZM256.817 3.74349L262.081 39.845L296.642 52.8867L290.911 15.8962L256.817 3.74349Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M238.904 56.6865L233.336 18.6514L232.449 18.7814L238.094 57.3397L275.048 71.2435L275.363 70.4043L238.904 56.6865Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M238.559 56.5929L261.378 40.0112L261.904 40.7368L239.084 57.3185L238.559 56.5929Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M274.281 70.5812L297.371 53.8032L297.897 54.5288L274.808 71.3068L274.281 70.5812Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M232.343 19.0424L255.58 2.15723L256.106 2.88283L232.869 19.7681L232.343 19.0424Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M297.779 53.7134L333.777 66.5447L339.829 105.58L303.334 91.8112L297.779 53.7134ZM298.881 55.0574L304.145 91.1585L338.708 104.199L332.974 67.2102L298.881 55.0574Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M280.97 107.997L275.401 69.9683L274.516 70.0983L280.16 108.651L317.115 122.555L317.429 121.716L280.97 107.997Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M280.625 107.904L303.442 91.3252L303.967 92.0505L281.152 108.63L280.625 107.904Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M316.348 121.893L339.437 105.115L339.964 105.841L316.874 122.619L316.348 121.893Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M274.409 70.354L297.647 53.4688L298.172 54.1943L274.935 71.0795L274.409 70.354Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M314.281 -0.844727L350.282 11.9843L356.33 51.0209L319.836 37.2534L314.281 -0.844727ZM315.381 0.499208L320.646 36.6008L355.21 49.6403L349.478 12.6498L315.381 0.499208Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M297.471 53.4402L291.906 15.4082L291.02 15.5382L296.662 54.0934L333.615 67.9972L333.931 67.1579L297.471 53.4402Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M297.129 53.3499L319.949 36.7681L320.474 37.4937L297.655 54.0755L297.129 53.3499Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M332.849 67.3351L355.939 50.5571L356.464 51.2827L333.375 68.0607L332.849 67.3351Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M290.911 15.7959L314.147 -1.08936L314.674 -0.363743L291.436 16.5215L290.911 15.7959Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M-26.9046 554.592L9.09259 567.424L15.1464 606.461L-21.3495 592.69L-26.9046 554.592ZM-25.8041 555.936L-20.54 592.038L14.0263 605.08L8.29031 568.089L-25.8041 555.936Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M-8.33383 622.774L14.7552 605.996L15.2817 606.722L-7.80822 623.5L-8.33383 622.774Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M-10.4014 500.037L25.5976 512.863L31.6479 551.903L-4.84805 538.132L-10.4014 500.037ZM-9.30091 501.381L-4.03861 537.48L30.5277 550.522L24.7944 513.528L-9.30091 501.381Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M-27.2109 554.321L-32.7785 516.287L-33.665 516.417L-28.0203 554.975L8.93318 568.879L9.24838 568.04L-27.2109 554.321Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.16672 568.217L31.2566 551.438L31.7822 552.165L8.6932 568.943L8.16672 568.217Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M31.5843 551.614L67.5851 564.444L73.6336 603.481L37.1394 589.712L31.5843 551.614ZM32.6848 552.958L37.9489 589.06L72.5135 602.1L66.7819 565.109L32.6848 552.958Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14.7748 605.899L9.20897 567.867L8.32339 567.997L13.9654 606.552L50.9215 620.459L51.2358 619.62L14.7748 605.899Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14.4319 605.809L37.2514 589.227L37.7779 589.952L14.9584 606.534L14.4319 605.809Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M50.1551 619.797L73.2441 603.019L73.7706 603.744L50.6807 620.522L50.1551 619.797Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.21417 568.255L31.4509 551.369L31.9765 552.095L8.73975 568.98L8.21417 568.255Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M73.6506 602.926L109.649 615.758L115.699 654.797L79.2057 641.024L73.6506 602.926ZM74.752 604.27L80.0161 640.372L114.579 653.416L108.846 616.423L74.752 604.27Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M56.8411 657.211L51.2725 619.181L50.387 619.311L56.0316 657.864L92.9878 671.771L93.303 670.931L56.8411 657.211Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M56.4955 657.123L79.3149 640.541L79.8414 641.266L57.0219 657.848L56.4955 657.123Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M92.2213 671.108L115.311 654.331L115.837 655.056L92.747 671.834L92.2213 671.108Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M50.2805 619.567L73.5145 602.684L74.0401 603.41L50.8061 620.293L50.2805 619.567Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M90.1521 548.369L126.15 561.2L132.204 600.238L95.7072 586.467L90.1521 548.369ZM91.2526 549.713L96.5167 585.814L131.084 598.856L125.347 561.865L91.2526 549.713Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M73.3426 602.653L67.7767 564.621L66.8911 564.751L72.5331 603.306L109.489 617.213L109.804 616.374L73.3426 602.653Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M73.0005 602.562L95.82 585.98L96.3456 586.707L73.5261 603.288L73.0005 602.562Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M108.723 616.551L131.813 599.773L132.338 600.499L109.248 617.277L108.723 616.551Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M66.7837 565.012L90.0213 548.126L90.5469 548.852L67.3102 565.737L66.7837 565.012Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.56909 445.865L41.5699 458.693L47.6184 497.73L11.1242 483.963L5.56909 445.865ZM6.67043 447.209L11.9346 483.31L46.4982 496.35L40.7667 459.36L6.67043 447.209Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M-11.2404 500.149L-16.808 462.115L-17.6936 462.245L-12.0498 500.803L24.9037 514.707L25.2189 513.867L-11.2404 500.149Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M-11.5851 500.056L11.2343 483.474L11.7609 484.2L-11.0586 500.782L-11.5851 500.056Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M24.1381 514.044L47.2271 497.267L47.7536 497.992L24.6637 514.77L24.1381 514.044Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M-17.801 462.506L5.43574 445.62L5.96225 446.345L-17.2754 463.231L-17.801 462.506Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M47.6354 497.177L83.6335 510.008L89.682 549.045L53.1914 535.275L47.6354 497.177ZM48.7367 498.521L54.0008 534.623L88.5618 547.664L82.8303 510.674L48.7367 498.521Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M30.8259 551.462L25.2556 513.429L24.3691 513.559L30.0174 552.115L66.9709 566.019L67.2851 565.179L30.8259 551.462Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M30.4785 551.37L53.298 534.789L53.8236 535.515L31.0042 552.096L30.4785 551.37Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M66.2044 565.356L89.2934 548.578L89.8199 549.303L66.73 566.082L66.2044 565.356Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M24.2653 513.817L47.4993 496.934L48.0258 497.659L24.7917 514.542L24.2653 513.817Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M64.1369 442.619L100.135 455.45L106.186 494.485L69.692 480.717L64.1369 442.619ZM65.2382 443.963L70.5023 480.064L105.066 493.104L99.3318 456.116L65.2382 443.963Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M47.3274 496.904L41.7625 458.872L40.876 459.002L46.518 497.556L83.4715 511.461L83.7867 510.621L47.3274 496.904Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M46.9827 496.811L69.8022 480.229L70.3287 480.954L47.5092 497.536L46.9827 496.811Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M82.7059 510.799L105.795 494.021L106.321 494.746L83.2315 511.524L82.7059 510.799Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M40.7668 459.259L64.0044 442.374L64.53 443.1L41.2924 459.985L40.7668 459.259Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M106.125 494.199L142.121 507.028L148.174 546.065L111.678 532.294L106.125 494.199ZM107.226 495.543L112.488 531.641L147.054 544.684L141.317 507.693L107.226 495.543Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M89.3131 548.48L83.7472 510.449L82.8616 510.579L88.5036 549.134L125.46 563.04L125.775 562.201L89.3131 548.48Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M88.971 548.391L111.79 531.809L112.316 532.534L89.4966 549.116L88.971 548.391Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M124.693 562.379L147.783 545.601L148.309 546.326L125.219 563.104L124.693 562.379Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M82.7542 510.84L105.992 493.955L106.517 494.68L83.2807 511.566L82.7542 510.84Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M148.192 545.511L184.189 558.343L190.237 597.379L153.747 583.609L148.192 545.511ZM149.292 546.855L154.556 582.957L189.117 595.999L183.386 559.008L149.292 546.855Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M131.382 599.795L125.811 561.763L124.925 561.893L130.573 600.449L167.523 614.354L167.838 613.515L131.382 599.795Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M131.034 599.704L153.853 583.123L154.38 583.849L131.56 600.43L131.034 599.704Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M166.76 613.69L189.85 596.912L190.375 597.637L167.286 614.415L166.76 613.69Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M124.818 562.154L148.055 545.269L148.581 545.994L125.343 562.879L124.818 562.154Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M164.693 490.953L200.69 503.785L206.742 542.819L170.248 529.051L164.693 490.953ZM165.793 492.297L171.058 528.398L205.622 541.438L199.888 504.45L165.793 492.297Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M147.884 545.238L142.312 507.205L141.427 507.335L147.074 545.891L184.028 559.795L184.343 558.956L147.884 545.238Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M147.539 545.144L170.355 528.564L170.881 529.291L148.064 545.87L147.539 545.144Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M183.261 559.133L206.351 542.354L206.877 543.08L183.787 559.858L183.261 559.133Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M141.322 507.593L164.56 490.708L165.085 491.434L141.848 508.319L141.322 507.593Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M-19.4593 339.605L16.5388 352.437L22.5873 391.474L-13.9033 377.704L-19.4593 339.605ZM-18.358 340.949L-13.0939 377.051L21.4671 390.093L15.7356 353.102L-18.358 340.949Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M-36.2688 393.89L-41.8391 355.857L-42.7256 355.987L-37.0773 394.544L-0.127434 408.45L0.18774 407.611L-36.2688 393.89Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M-0.89035 407.785L22.1996 391.007L22.7252 391.733L-0.364741 408.511L-0.89035 407.785Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M22.6043 390.919L58.6024 403.75L64.6536 442.785L28.1594 429.017L22.6043 390.919ZM23.7057 392.263L28.9698 428.365L63.5334 441.404L57.7992 404.416L23.7057 392.263Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.79476 445.204L0.229799 407.172L-0.656647 407.301L4.98532 445.857L41.9388 459.761L42.254 458.921L5.79476 445.204Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.45001 445.111L28.2695 428.529L28.796 429.255L5.9765 445.836L5.45001 445.111Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M41.1732 459.099L64.2622 442.321L64.7887 443.046L41.6988 459.824L41.1732 459.099Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M-0.7659 407.559L22.4717 390.674L22.9973 391.4L-0.24029 408.285L-0.7659 407.559Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M39.1085 336.359L75.1066 349.191L81.155 388.228L44.6645 374.457L39.1085 336.359ZM40.2098 337.703L45.4739 373.805L80.0349 386.847L74.3034 349.856L40.2098 337.703Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M22.2963 390.646L16.7304 352.615L15.8448 352.745L21.4868 391.3L58.4403 405.204L58.7555 404.365L22.2963 390.646Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M21.9542 390.557L44.7737 373.975L45.2993 374.7L22.4798 391.282L21.9542 390.557Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M57.6738 404.541L80.7637 387.763L81.2893 388.489L58.2003 405.267L57.6738 404.541Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.7356 353.002L38.9723 336.117L39.4988 336.843L16.2612 353.728L15.7356 353.002Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M81.0968 387.939L117.092 400.767L123.142 439.808L86.6492 426.034L81.0968 387.939ZM82.1973 389.283L87.4596 425.381L122.022 438.426L116.289 401.433L82.1973 389.283Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M64.2838 442.227L58.7161 404.191L57.8306 404.321L63.4743 442.879L100.429 456.783L100.743 455.944L64.2838 442.227Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M63.939 442.133L86.7585 425.551L87.285 426.277L64.4655 442.859L63.939 442.133Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M99.6621 456.12L122.751 439.343L123.278 440.069L100.188 456.847L99.6621 456.12Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M57.7231 404.582L80.9607 387.697L81.4863 388.422L58.2496 405.308L57.7231 404.582Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M123.16 439.254L159.158 452.085L165.21 491.119L128.716 477.352L123.16 439.254ZM124.261 440.598L129.525 476.699L164.09 489.739L158.354 452.751L124.261 440.598Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M106.351 493.538L100.78 455.505L99.8941 455.635L105.541 494.192L142.495 508.095L142.81 507.256L106.351 493.538Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M106.006 493.445L128.822 476.865L129.349 477.591L106.532 494.17L106.006 493.445Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M141.729 507.433L164.818 490.655L165.344 491.38L142.254 508.158L141.729 507.433Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M99.7893 455.894L123.027 439.009L123.553 439.734L100.316 456.619L99.7893 455.894Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M139.661 384.696L175.663 397.525L181.711 436.561L145.217 422.794L139.661 384.696ZM140.762 386.04L146.026 422.141L180.591 435.181L174.859 398.19L140.762 386.04Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M122.851 438.98L117.284 400.945L116.398 401.075L122.043 439.634L158.996 453.537L159.311 452.698L122.851 438.98Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M122.508 438.887L145.327 422.305L145.853 423.031L123.033 439.612L122.508 438.887Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M158.23 452.875L181.319 436.097L181.845 436.822L158.756 453.6L158.23 452.875Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M116.291 401.336L139.529 384.451L140.054 385.176L116.817 402.061L116.291 401.336Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M55.0798 282.187L91.077 295.018L97.1255 334.055L60.6349 320.285L55.0798 282.187ZM56.1802 283.53L61.4444 319.632L96.0053 332.674L90.2738 295.683L56.1802 283.53Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M38.2668 336.474L32.7009 298.442L31.8154 298.572L37.4574 337.128L74.4135 351.035L74.7287 350.195L38.2668 336.474Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M37.9247 336.384L60.7442 319.803L61.2698 320.528L38.4503 337.11L37.9247 336.384Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M73.647 350.372L96.7369 333.594L97.2625 334.32L74.1726 351.098L73.647 350.372Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M31.7061 298.83L54.9428 281.945L55.4693 282.67L32.2317 299.556L31.7061 298.83Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M97.1425 333.501L133.141 346.332L139.192 385.367L102.699 371.599L97.1425 333.501ZM98.2439 334.845L103.508 370.946L138.072 383.986L132.337 346.998L98.2439 334.845Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M80.333 387.785L74.7645 349.756L73.8789 349.886L79.5236 388.439L116.48 402.345L116.795 401.506L80.333 387.785Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M79.9909 387.696L102.807 371.116L103.333 371.842L80.5165 388.421L79.9909 387.696Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M115.713 401.684L138.803 384.906L139.329 385.631L116.24 402.409L115.713 401.684Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M73.7723 350.141L97.01 333.256L97.5356 333.982L74.2979 350.867L73.7723 350.141Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M113.644 278.944L149.645 291.773L155.696 330.812L119.199 317.042L113.644 278.944ZM114.745 280.288L120.009 316.389L154.576 329.431L148.843 292.438L114.745 280.288Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M96.8345 333.229L91.2695 295.197L90.3831 295.327L96.025 333.881L132.981 347.789L133.296 346.949L96.8345 333.229Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M96.4925 333.138L119.312 316.556L119.838 317.281L97.018 333.863L96.4925 333.138Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M132.215 347.126L155.305 330.348L155.83 331.074L132.74 347.852L132.215 347.126Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M90.2756 295.586L113.513 278.702L114.039 279.427L90.8021 296.313L90.2756 295.586Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M155.631 330.523L191.633 343.352L197.682 382.389L161.187 368.621L155.631 330.523ZM156.733 331.867L161.997 367.969L196.561 381.008L190.83 344.017L156.733 331.867Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M138.822 384.808L133.257 346.776L132.371 346.906L138.013 385.462L174.967 399.365L175.282 398.526L138.822 384.808Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M138.478 384.715L161.298 368.133L161.823 368.858L139.004 385.44L138.478 384.715Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M174.201 398.703L197.29 381.925L197.816 382.65L174.726 399.428L174.201 398.703Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M132.261 347.164L155.499 330.279L156.025 331.004L132.788 347.89L132.261 347.164Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M197.699 381.835L233.696 394.666L239.744 433.703L203.254 419.933L197.699 381.835ZM198.799 383.179L204.063 419.28L238.624 432.322L232.894 395.332L198.799 383.179Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M180.889 436.119L175.321 398.09L174.434 398.22L180.08 436.773L217.036 450.68L217.35 449.841L180.889 436.119Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M180.544 436.031L203.363 419.45L203.889 420.176L181.069 436.758L180.544 436.031Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M216.269 450.018L239.358 433.24L239.885 433.965L216.795 450.743L216.269 450.018Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M174.329 398.476L197.563 381.593L198.088 382.318L174.854 399.201L174.329 398.476Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M214.2 327.277L250.197 340.109L256.249 379.143L219.755 365.375L214.2 327.277ZM215.301 328.621L220.565 364.723L255.129 377.762L249.394 340.774L215.301 328.621Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M197.391 381.562L191.825 343.53L190.939 343.66L196.581 382.215L233.536 396.122L233.852 395.283L197.391 381.562Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M197.048 381.472L219.867 364.89L220.394 365.615L197.574 382.197L197.048 381.472Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M232.771 395.46L255.86 378.682L256.386 379.407L233.296 396.185L232.771 395.46Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M190.829 343.918L214.067 327.032L214.592 327.758L191.356 344.643L190.829 343.918Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M-36.4264 393.897L-0.429226 406.728L5.61926 445.765L-30.8713 431.995L-36.4264 393.897ZM-35.326 395.241L-30.0619 431.342L4.4991 444.385L-1.23151 407.394L-35.326 395.241Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M-17.8583 462.076L5.23154 445.298L5.75715 446.025L-17.3319 462.802L-17.8583 462.076Z"
              fill="black"
              fillOpacity="0.1"
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_198_37551">
          <rect
            width="702"
            height="754"
            fill="white"
            transform="translate(-323)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}

export default PatternIcon
