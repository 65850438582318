import React from 'react'
import { Button, Stack, styled, Typography } from '@mui/material'
import LeftArrowIcon from 'src/icons/LeftArrowIcon'
import RightArrowIcon from 'src/icons/RightArrowIcon'

const ArrowButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.bw.yellow,
  minWidth: 45,
  height: 45,
  borderRadius: 0,
}))

interface ICarouselHeaderProps {
  onNextClick?: () => void
  onBackClick?: () => void
  title: string
  renderBtns?: boolean
}

const CarouselHeader: React.FC<ICarouselHeaderProps> = ({
  title,
  onNextClick,
  onBackClick,
  renderBtns = true,
}) => {
  return (
    <Stack
      direction="row"
      flex={1}
      justifyContent="space-between"
      alignItems="center"
    >
      <Typography
        fontSize={(theme) => theme.typography.fontSize * 2}
        fontWeight="bold"
      >
        {title}
      </Typography>

      {renderBtns && (
        <Stack direction="row" spacing={2}>
          <ArrowButton onClick={onBackClick}>
            <LeftArrowIcon />
          </ArrowButton>
          <ArrowButton onClick={onNextClick}>
            <RightArrowIcon />
          </ArrowButton>
        </Stack>
      )}
    </Stack>
  )
}

export default CarouselHeader
