import { createAsyncThunk } from '@reduxjs/toolkit'
import { http } from 'src/libs'
import { NOT_FOUND } from 'src/utils/errorCode'
import Cookies from 'universal-cookie'

import {
  type ICardResponse,
  type IFormPayload,
  type IFraudPayload,
  type IScheme,
} from './type'

interface IResponse {
  errorCode: string
  responseCode: string
  responseMessage: string
  responseMethod: string
}

export interface IAccessTokenResponse extends IResponse {
  responseData: string | IScheme[]
}

export interface ICardsApiResponse extends IResponse {
  responseData: ICardResponse
}

const getBrowserInfo = () => {
  const userAgent = navigator.userAgent
  let browserName = 'Unknown'
  let browserVersion = 'Unknown'

  if (userAgent.includes('Firefox')) {
    browserName = 'Firefox'
    browserVersion = userAgent.match(/Firefox\/([0-9.]+)/)?.[1] || 'Unknown'
  } else if (userAgent.includes('Edg')) {
    browserName = 'Edge'
    browserVersion = userAgent.match(/Edg\/([0-9.]+)/)?.[1] || 'Unknown'
  } else if (
    userAgent.includes('Chrome') &&
    !userAgent.includes('Edg') &&
    !userAgent.includes('OPR')
  ) {
    browserName = 'Chrome'
    browserVersion = userAgent.match(/Chrome\/([0-9.]+)/)?.[1] || 'Unknown'
  } else if (userAgent.includes('Safari') && !userAgent.includes('Chrome')) {
    browserName = 'Safari'
    browserVersion = userAgent.match(/Version\/([0-9.]+)/)?.[1] || 'Unknown'
  } else if (userAgent.includes('OPR')) {
    browserName = 'Opera'
    browserVersion = userAgent.match(/OPR\/([0-9.]+)/)?.[1] || 'Unknown'
  } else if (userAgent.includes('MSIE') || userAgent.includes('Trident')) {
    browserName = 'Internet Explorer'
    browserVersion =
      userAgent.match(/(?:MSIE\s|rv:)([0-9.]+)/)?.[1] || 'Unknown'
  }

  return { browserName, browserVersion }
}

export const getCard = createAsyncThunk(
  'getCard',
  async (payload: IFormPayload, thunkApi) => {
    try {
      const cookie = new Cookies()
      const { browserName, browserVersion } = getBrowserInfo()
      const { response, error } = await http(
        {
          method: 'POST',
          path: {
            url: 'GET_CARD',
          },
          baseUrl: process.env.REACT_APP_API_BASE_URL,
          data: payload,
          userAuthToken: cookie.get('accessToken'),
        },
        {
          headers: {
            device_version: browserVersion,
            device_model: browserName,
            device_type: '3',
            location: `${payload.latitude}/${payload.longitude}`,
          },
        },
      )

      if (error) {
        return thunkApi.rejectWithValue(error)
      }

      const data = response?.data as ICardsApiResponse

      if (data.errorCode === NOT_FOUND) {
        return thunkApi.rejectWithValue({ message: 'Card Not Found!' })
      }

      return thunkApi.fulfillWithValue(data.responseData)
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  },
)

export const reportFraudulent = createAsyncThunk(
  'reportFradulent',
  async (payload: IFraudPayload, thunkApi) => {
    try {
      const { response, error } = await http({
        method: 'POST',
        path: {
          url: 'FRAUDULENT',
        },
        data: payload,
      })

      if (error) {
        return thunkApi.rejectWithValue(error)
      }

      const data = response?.data as ICardsApiResponse

      if (data.errorCode === NOT_FOUND) {
        return thunkApi.rejectWithValue({ message: 'Card Not Found!' })
      }
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  },
)
