import React from 'react'
import { type ICard } from 'src/utils/types/card'

import RenderCardDetails from '../RenderCardDetails'

interface IRenderCarouselCardProps {
  cards?: ICard[]
}

const RenderCarouselCard: React.FC<IRenderCarouselCardProps> = ({ cards }) => {
  if (!cards) {
    return <></>
  }

  return (
    <>
      {cards.map((card, key) => (
        <RenderCardDetails key={key} card={card} />
      ))}
    </>
  )
}

export default RenderCarouselCard
