import React from 'react'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Box, styled, Typography } from '@mui/material'
import type { ButtonProps } from '@mui/material/Button'

interface IButtonProps extends ButtonProps {
  title: string
}

const StyleButton = styled('button')<IButtonProps>(({ theme }) => ({
  backgroundColor: theme.palette.bw.white,
  border: '1px solid',
  borderColor: theme.palette.bw.greyLight,
  outline: 'none',
  padding: 0,
  margin: 0,
  minWidth: '164px',
  height: '48px',
  textTransform: 'capitalize',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  ':hover': {
    cursor: 'pointer',
  },
}))

const Button: React.FC<IButtonProps> = (props) => {
  return (
    <StyleButton variant="outlined" {...props}>
      <Typography
        sx={{ marginLeft: (theme) => theme.spacing(3), pointerEvent: 'none' }}
        textAlign="left"
        width="100%"
      >
        {props.title}
      </Typography>
      <Box
        component="span"
        sx={{
          width: '43%',
          backgroundColor: (theme) => theme.palette.bw.yellow,
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ChevronRightIcon fontSize="large" />
      </Box>
    </StyleButton>
  )
}

export default Button
