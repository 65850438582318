import React from 'react'
import { FormControl, FormLabel } from '@mui/material'
import Input from 'src/components/Input'
import { useAppDispatch, useAppSelect } from 'src/hooks/useRedux'

import { setPayload } from '../../ducks/home.reducer'

const RegistrationNumber: React.FC = () => {
  const { payload } = useAppSelect((state) => state.home)
  const dispatch = useAppDispatch()

  return (
    <FormControl sx={{ width: '100%' }}>
      <FormLabel
        sx={{
          fontSize: (theme) => theme.typography.fontSize + 2,
          fontWeight: (theme) => theme.typography.fontWeightRegular,
        }}
      >
        Registration number<span style={{ color: '#F90000' }}>*</span>
      </FormLabel>
      <Input
        value={payload.cardSerialNumber}
        onChange={(e) =>
          dispatch(
            setPayload({
              ...payload,
              cardSerialNumber: `${e.target.value}`.trim(),
            }),
          )
        }
        placeholder="Enter registration number"
        required
      />
    </FormControl>
  )
}

export default RegistrationNumber
