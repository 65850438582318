import React from 'react'
import { SvgIcon } from '@mui/material'

import { type SvgIconType } from './SvgIconType'

const LeftArrowIcon: SvgIconType = (props) => {
  return (
    <SvgIcon viewBox="0 0 12 21" fill="none" {...props}>
      <path
        d="M0.361366 11.6128L9.42344 20.6747C9.63304 20.8844 9.91283 21 10.2112 21C10.5095 21 10.7893 20.8844 10.9989 20.6747L11.6662 20.0075C12.1005 19.5727 12.1005 18.8661 11.6662 18.4321L4.05659 10.8224L11.6747 3.20431C11.8843 2.99455 12 2.71493 12 2.41676C12 2.11826 11.8843 1.83864 11.6747 1.62871L11.0073 0.961683C10.7976 0.751922 10.5179 0.636364 10.2196 0.636364C9.92127 0.636364 9.64148 0.751922 9.43189 0.961683L0.361366 10.0319C0.151275 10.2423 0.0358826 10.5232 0.0365444 10.8219C0.0358825 11.1217 0.151275 11.4025 0.361366 11.6128Z"
        fill="black"
      />
    </SvgIcon>
  )
}

export default LeftArrowIcon
