import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import { Navigate, useRoutes } from 'react-router-dom'
import { ThemeProvider } from '@mui/material'
import Cookies from 'universal-cookie'

  import AppErrorBoundry from './components/ErrorBoundary'
import { store } from './libs'
import routes from './route'
import theme from './theme'

const App: React.FC = () => {
  const cookies = new Cookies()

  const token = cookies.get('token') // Check if token exists
  const isGuest = cookies.get('isGuest') // Check if user is guest
   // Secure Route logic
  const securedRoutes = routes.map((route) => {
    if (route.secure && !token && (route.path !== '/' || !isGuest)) {
      return {
        ...route,
        element: <Navigate to="/login" replace />, // Redirect to login if secure route and no token
      }
    }
    return route
  })

  const content = useRoutes(securedRoutes)

  return (
    <HelmetProvider>
      <Helmet
        title={process.env.REACT_APP_NAME || 'CSCS Smart Check'}
        defaultTitle={process.env.REACT_APP_NAME || 'CSCS Smart Check'}
      />
      <AppErrorBoundry>
        <Provider store={store}>
          <ThemeProvider theme={theme}>{content}</ThemeProvider>
        </Provider>
      </AppErrorBoundry>
    </HelmetProvider>
  )
}

export default App
