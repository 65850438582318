import React from 'react'
import { IconButton, Stack, Typography } from '@mui/material'
import InfoIcon from 'src/icons/InfoIcon'

interface IFormHeaderProps {
  setInfoState: () => void
}

const FormHeader: React.FC<IFormHeaderProps> = (props) => {
  return (
    <Stack direction="row" justifyContent="center" alignItems="center">
      <Typography
        fontSize="30px"
        fontWeight={(theme) => theme.typography.fontWeightBold}
        textAlign="center"
        fontFamily="MetaProBold"
      >
        Check a Card
      </Typography>
      <IconButton onClick={props.setInfoState}>
        <InfoIcon />
      </IconButton>
    </Stack>
  )
}

export default FormHeader
