import React from 'react'
import { SvgIcon } from '@mui/material'

import { type SvgIconType } from './SvgIconType'

const InfoIcon: SvgIconType = (props) => {
  return (
    <SvgIcon width="26" height="26" viewBox="0 0 26 26" fill="none" {...props}>
      <path
        d="M13 0.5C19.9038 0.5 25.5 6.09625 25.5 13C25.5026 16.2818 24.2144 19.4331 21.9136 21.7733C19.6128 24.1136 16.4839 25.4551 13.2025 25.5083C9.92112 25.5614 6.75042 24.322 4.375 22.0575C1.99957 19.793 0.609986 16.6852 0.50625 13.405L0.5 13L0.505 12.65C0.69 5.90875 6.2125 0.5 13 0.5ZM13 11.75H11.75L11.6038 11.7588C11.2999 11.7949 11.0199 11.9412 10.8168 12.17C10.6136 12.3987 10.5014 12.694 10.5014 13C10.5014 13.306 10.6136 13.6013 10.8168 13.83C11.0199 14.0588 11.2999 14.2051 11.6038 14.2413L11.75 14.25V18L11.7588 18.1463C11.7917 18.4253 11.9176 18.6851 12.1163 18.8837C12.3149 19.0824 12.5747 19.2083 12.8538 19.2413L13 19.25H14.25L14.3963 19.2413C14.6753 19.2083 14.9351 19.0824 15.1337 18.8837C15.3324 18.6851 15.4583 18.4253 15.4913 18.1463L15.5 18L15.4913 17.8538C15.4614 17.5987 15.3537 17.3591 15.1828 17.1674C15.0119 16.9758 14.7862 16.8414 14.5363 16.7825L14.3963 16.7575L14.25 16.75V13L14.2413 12.8538C14.2083 12.5747 14.0824 12.3149 13.8837 12.1163C13.6851 11.9176 13.4253 11.7917 13.1463 11.7588L13 11.75ZM13.0125 8L12.8538 8.00875C12.5499 8.04489 12.2699 8.1912 12.0668 8.41996C11.8636 8.64872 11.7514 8.94405 11.7514 9.25C11.7514 9.55595 11.8636 9.85128 12.0668 10.08C12.2699 10.3088 12.5499 10.4551 12.8538 10.4913L13 10.5L13.1588 10.4913C13.4626 10.4551 13.7426 10.3088 13.9457 10.08C14.1489 9.85128 14.2611 9.55595 14.2611 9.25C14.2611 8.94405 14.1489 8.64872 13.9457 8.41996C13.7426 8.1912 13.4626 8.04489 13.1588 8.00875L13.0125 8Z"
        fill="#E49894"
      />
    </SvgIcon>
  )
}

export default InfoIcon
