import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

import { ProfileAction } from './Actions'

 const initialState = {
    loading: false,
    error: '',
    payload: {
      registrationNumber: '',
      lastName: '',
      scheme: '',
      captchaToken: '',
    },
    activeCards: [],
    expiredOrCancelledCards: [],
  }
const profileReducer = createSlice({
  name: 'profileReducer',
  initialState,
  reducers: {
    setPayload: (state, { payload }: PayloadAction<any>) => {
      state = payload
    },
    clearError: (state) => {
      state.error = ''
    },
  },
  extraReducers(builder) {
    // Get Cards
    builder.addCase(ProfileAction.pending, (state) => {
      state.loading = true
    })
    builder.addCase(ProfileAction.fulfilled, (state, { payload }) => {
      state.loading = false
      state.payload = payload
  })
}
})

export const { setPayload, clearError } = profileReducer.actions

export default profileReducer.reducer
