import React from 'react'
import { Stack, Typography } from '@mui/material'

const PageHeader: React.FC = () => {
  return (
    <Stack component="div" justifyContent="center" alignItems="center" my={10}>
      <Typography fontSize="60px" fontWeight="700" fontFamily="MetaProBold">
        CSCS Smart Check
      </Typography>
      <Typography fontSize={22} sx={{ maxWidth: '70%' }} textAlign="center">
        Verify all 2.1 million CSCS-logoed cards quickly and easily using CSCS
        Smart Check
      </Typography>
    </Stack>
  )
}

export default PageHeader
