import React, { useMemo, useState } from 'react'
import { Box, Stack } from '@mui/material'
import LightBackground from 'src/components/LightBackground'
import { useAppSelect } from 'src/hooks/useRedux'
import { type ICard } from 'src/utils/types/card'

import CarouselDots from './CarouselDots'
import CarouselHeader from './CarouselHeader'
import RenderCarouselCard from './RenderCarouselCards'

interface ICarouselProps {
  title: string
}

const Carousel: React.FC<ICarouselProps> = ({ title }) => {
  const { expiredOrCancelledCards } = useAppSelect((state) => state.home)
  const [active, setActive] = useState<number>(() => 0)

  const cardsData = useMemo(() => {
    const result: ICard[][] = []
    for (let i = 0; i < expiredOrCancelledCards.length; i += 3) {
      result.push(expiredOrCancelledCards.slice(i, i + 3))
    }

    return result || []
  }, [expiredOrCancelledCards])

  const onNextClick = () => {
    if (active >= cardsData.length - 1) {
      setActive(0)
      return
    }

    setActive(active + 1)
  }
  const onBackClick = () => {
    if (active <= 0) {
      setActive(cardsData.length - 1)
      return
    }

    setActive(active - 1)
  }

  if (!expiredOrCancelledCards.length) {
    return <></>
  }

  return (
    <LightBackground>
      <Box component="div" sx={{ width: '70%' }}>
        <CarouselHeader
          title={title}
          onNextClick={onNextClick}
          onBackClick={onBackClick}
          renderBtns={cardsData.length > 1}
        />

        <Stack
          direction="row"
          justifyContent="flex-start"
          columnGap={5}
          mt={15}
        >
          <RenderCarouselCard cards={cardsData[active]} />
        </Stack>

        <CarouselDots cards={cardsData} active={active} />
      </Box>
    </LightBackground>
  )
}

export default Carousel
