import { configureStore } from '@reduxjs/toolkit'
import homeReducer from 'src/features/home/ducks/home.reducer'
import loginReducer from 'src/features/login/StateHandler/login.reducer'
 import otpReducer from 'src/features/otp/StateHandler/otp.reducer'
import profileReducer from 'src/features/profile/StateHandler/profile.reducer'
import reportReducer from 'src/features/report/ducks/report.reducer'

import accessTokenReducer from './accessToken/accessToken.reducer'
import AccessTokenByRefreshReducer from './getAccessTokenByRefresh/AccessTokenByRefresh.reducer'
import schemeReducer from './scheme/scheme.reducer'

const store = configureStore({
  reducer: {
    home: homeReducer,
    access_token: accessTokenReducer,
    schemes: schemeReducer,
    reports: reportReducer,
    otpeReducer: otpReducer,
    getAccessToken: AccessTokenByRefreshReducer,
    loginReducer,
    profileReducer,
  },
  middleware(getDefaultMiddleware) {
    return getDefaultMiddleware({
      serializableCheck: false,
    })
  },
  devTools: process.env.NODE_ENV === 'development',
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>

export default store
