import React from 'react'
 import { useNavigate } from 'react-router-dom'
import { Box, Stack, Toolbar } from '@mui/material'
import { ExtraLinks } from 'src/utils/extraLinks'
 import { logOut } from 'src/utils/islogin'

import Image from './Image'
import Link from './Link'
 import ProfileMenu from './ProfilePopup'

const Header: React.FC = () => {
   const navigation = useNavigate()

   const handleLogoutClick = () => {
     logOut()
   }

   const handleClickProfile = () => {
     navigation('/profile')
   }
  return (
    <Box sx={{
        position: 'relative',
        backgroundImage: 'url(/images/header_bg.png)',
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        height: '195px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        width: '100%',
        flexDirection: 'row',
      }}
      data-testid="appbar"
    >
      <Toolbar data-testid="toolbar" sx={{ width: '70%' }}>
        <Link to="https://CSCS.uk.com/SmartCheck"
          sx={{
            width: 225,
            height: 97,
            marginRight: (theme) => theme.spacing(3),
          }}
        >
          <Image
            src="/images/logos/logo@2x.png"
            alt="CSCS smartcheck web app"
          />
        </Link>

        <Stack
          direction="row"
          spacing={10}
          flex={1}
          alignItems="center"
          justifyContent="flex-end"
        >
          <Link
            to={ExtraLinks.playStore}
            sx={{ width: '144px', height: '43px' }}
            data-testid="play-store-btn"
          >
            <Image src="/images/play-store.png" />
          </Link>
          <Link
            data-testid="app-store-btn"
            to={ExtraLinks.appStore}
            sx={{ width: '144px', height: '43px' }}
          >
            <Image src="/images/app-store.png" />
          </Link>
        </Stack>
           <ProfileMenu handleClickProfile={handleClickProfile} handleClickLogout={handleLogoutClick} />
        </Toolbar>
    </Box>
  )
}

export default Header
