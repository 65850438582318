import { createAsyncThunk } from '@reduxjs/toolkit'
import { http } from 'src/libs'

export const getAccessTokenByRefresh = createAsyncThunk(
  'accessTokenByRefreshReducer',
  async (payload:
    {
      loginCode?: string
      username?: string
    }
    , thunkApi) => {
    try {
      const { response, error }: any = await http({
        method: 'POST',
        baseUrl: process.env.REACT_APP_API_BASE_URL,
        path: {
          url: 'REFRESH_TOKEN',
        },
        data: payload,
      })

      if (error?.status === 404) {
        return thunkApi.rejectWithValue({ message: 'Not Found!' })
      }

      if (error?.status) {
        return thunkApi.rejectWithValue(error)
      }
      console.log(response, 'response')

      return thunkApi.fulfillWithValue(response)
     } catch (err) {
      return thunkApi.rejectWithValue(err)
    }
  }
)
