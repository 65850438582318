import { styled } from '@mui/material'

const LinkButton = styled('button')(({ theme }) => ({
  background: 'transparent',
  border: 'none',
  borderBottom: '1px solid',
  borderColor: theme.palette.primary.main,
  color: theme.palette.primary.main,
  padding: 0,
  margin: 0,
  ':hover': {
    cursor: 'pointer',
  },
}))

export default LinkButton
