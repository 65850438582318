import { createAsyncThunk } from '@reduxjs/toolkit'
import { http } from 'src/libs'
import { type IReportApiResponse } from 'src/utils/types/response'

export const sendFraudulentReport = createAsyncThunk(
  'sendFraudulentReport',
  async (data: FormData, thunkApi) => {
    try {
      const { response, error } = await http(
        {
          method: 'POST',
          path: {
            url: 'FRAUDULENT',
          },
          data,
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )

      if (error) {
        return thunkApi.rejectWithValue(error.message)
      }

      const { responseCode, responseMessage, responseData } =
        response?.data as IReportApiResponse

      if (responseCode !== '200') {
        return thunkApi.rejectWithValue(responseMessage)
      }

      return thunkApi.fulfillWithValue({ ...responseData })
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message || 'Something went wrong')
    }
  },
)
