import { createTheme } from '@mui/material'

const defaultTheme = createTheme({
  typography: {
    fontFamily: 'Calibri, MetaProBold',
    fontSize: 15,
    fontWeightBold: 700,
    fontWeightMedium: 600,
    fontWeightLight: 500,
    fontWeightRegular: 400,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          '&:hover': {
            backgroundColor: '#026AAB',
          },
        },
        outlined: {
          border: '1px solid',
        },
      },
    },
  },
  palette: {
    bw: {
      black: '#000000',
      blackLight: '#3C3C3C',
      white: '#ffffff',
      grey: '#B8C0C3',
      greyLight: '#262626',
      greyLightest: '#3537384D',
      greyLightest2: '#5A607F',
      backgroundColor: '#F7F7F7',
      blackLightest: '#A1A7C4',
      green: '#31BD00',
      greybtn: '#DCDCDC',
      yellow: '#EFC73B',
      lightRed: '#E49894',
      bg: '#E3F1F8',
      red: '#B70606',
    },
    primary: {
      light: '#757ce8',
      main: '#007AC2',
      dark: '#002884',
    },
    secondary: {
      light: '#004F70',
      main: '#57A7D7',
      dark: '#004F70',
    },
    gradient: {
      default:
        'linear-gradient(0deg, rgba(0,85,136,1) 0%, rgba(3,136,217,1) 100%)',
    },
  },
  shape: {
    borderRadius: 8,
  },
  spacing: 2,
})

export default defaultTheme
