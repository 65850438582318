import React, { useMemo } from 'react'
import { Box, Chip, Stack } from '@mui/material'
import Image from 'src/components/Image'
import { useAppSelect } from 'src/hooks/useRedux'
import { hexToBase64 } from 'src/utils/hexToBase64'

import { type ICard } from '../../ducks/type'

import RenderCardFooter from './RenderCardFooter'
import RenderItem from './RenderItem'
import RenderQualifications from './RenderQualifications'

interface IRenderCardDetails {
  card: ICard | null
}

const RenderCardDetails: React.FC<IRenderCardDetails> = ({ card }) => {
  const {
    cardsResponseData: { scheme },
  } = useAppSelect((state) => state.home)
  const { schemeName } = scheme || {}

  const cardState = useMemo(() => {
    if (card?.isExpired) {
      return {
        label: 'Expired',
        color: 'bw.yellow',
      }
    }

    if (!card?.isValid) {
      return {
        label: 'Cancelled',
        color: 'bw.red',
      }
    }

    return {
      label: 'Active',
      color: 'bw.green',
    }
  }, [card])

  if (!card) {
    return <></>
  }

  const getImage = () => {
    if (!card.customerPhoto) {
      return '/images/no-photo.png'
    }

    if (card.customerPhoto.startsWith('http')) {
      return card.customerPhoto
    }

    return hexToBase64(card.customerPhoto)
  }

  const formatDate = () => {
    // If card expiry date is null or empty, return NA
    if (!card?.dateOfExpiry) {
      return 'NA'
    }

    const d1 = card?.dateOfExpiry?.split?.('/')?.reverse?.()?.join?.('-')
    const expirayDate = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
    }).format(new Date(`${d1}`))

    return expirayDate
  }

  const scaneDateTime = () => {
    const date = new Date()
    const timeFormat = new Intl.DateTimeFormat('en-US', {
      timeStyle: 'short',
    }).format(date)
    const dateFormat = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }).format(date)

    return dateFormat + ', ' + timeFormat
  }

  return (
    <Stack
      sx={{
        padding: (theme) => theme.spacing(8),
        border: '1px solid',
        borderColor: 'bw.blackLight',
        borderRadius: (theme) => theme.shape.borderRadius / 8,
        width: 411,
        minHeight: '392px',
        backgroundColor: 'bw.white',
      }}
      justifyContent="space-between"
      direction="column"
    >
      <Stack direction="row" justifyContent="space-between">
        <Stack spacing={5}>
          <Image
            src={getImage()}
            sx={{
              width: 114,
              height: 114,
            }}
          />
          <RenderItem label="Card Type" value={card.cardTypeName} />

          <RenderItem label="Scheme Name" value={schemeName} />
        </Stack>
        <Stack spacing={1}>
          <Stack alignItems="flex-end">
            <Chip
              label={cardState.label}
              sx={{
                backgroundColor: cardState.color,
                color: 'bw.white',
                minWidth: 75,
                height: 25,
              }}
            />
          </Stack>
          <RenderItem label="Name" value={card.customerName} />
          <RenderItem label="Reg No" value={card.registrationNumber} />
          <RenderItem label="Date of Expiration" value={formatDate()} />
          <RenderItem label="Scan Date" value={scaneDateTime()} />
        </Stack>
      </Stack>

      <Box my={1}>
        <RenderQualifications
          label="Qualifications"
          value={card.occupationQualifications}
        />
      </Box>

      <RenderCardFooter card={card} scheme={scheme} />
    </Stack>
  )
}

export default RenderCardDetails
