import React from 'react'
import { SvgIcon } from '@mui/material'

import { type SvgIconType } from './SvgIconType'

const RightArrowIcon: SvgIconType = (props) => {
  return (
    <SvgIcon viewBox="0 0 12 21" fill="none" {...props}>
      <path
        d="M11.6386 9.38723L2.57656 0.325319C2.36696 0.115559 2.08717 0 1.78884 0C1.49051 0 1.21072 0.115559 1.00112 0.325319L0.333762 0.992512C-0.100493 1.42726 -0.100493 2.13386 0.333762 2.56795L7.94341 10.1776L0.325319 17.7957C0.115724 18.0054 0 18.2851 0 18.5832C0 18.8817 0.115724 19.1614 0.325319 19.3713L0.992678 20.0383C1.20244 20.2481 1.48206 20.3636 1.7804 20.3636C2.07873 20.3636 2.35852 20.2481 2.56812 20.0383L11.6386 10.9681C11.8487 10.7577 11.9641 10.4768 11.9635 10.1781C11.9641 9.87827 11.8487 9.59749 11.6386 9.38723Z"
        fill="black"
      />
    </SvgIcon>
  )
}

export default RightArrowIcon
