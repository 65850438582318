import React from 'react'
import { Stack } from '@mui/material'

import ReporterEmail from './ReporterEmail'
import ReporterName from './ReporterName'
import ReporterPhoneNumber from './ReporterPhoneNumber'

const ReporterContactInfo: React.FC = () => {
  return (
    <div style={{ backgroundColor: '#fbfbfb', padding: 10, borderRadius: 5 }}>
      <h3>Reporter Contact Information</h3>
      <Stack component="div" spacing={20} flex={1}>
        <ReporterName />
        <ReporterEmail />
        <ReporterPhoneNumber />
      </Stack>
    </div>
  )
}

export default ReporterContactInfo
