import React from 'react'
import { Box } from '@mui/material'

interface ILightBackgroundProps {
  children: React.ReactNode
}

const LightBackground: React.FC<ILightBackgroundProps> = ({ children }) => {
  return (
    <Box
      component="div"
      sx={{
        backgroundImage: 'url(/images/hero.png)',
        width: '100%',
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        backgroundPositionY: 'top',
        padding: (theme) => theme.spacing(60, 0),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      {children}
    </Box>
  )
}

export default LightBackground
