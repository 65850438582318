import React from 'react'
import { Box, FormControl, FormLabel, TextField } from '@mui/material'
import { useAppDispatch, useAppSelect } from 'src/hooks/useRedux'

import { setReportPayload } from '../../ducks/report.reducer'

const ReporterName: React.FC = () => {
  const dispatch = useAppDispatch()
  const { payload, loading } = useAppSelect((state) => state.reports)

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setReportPayload({ ...payload, reporterName: e.target.value }))
  }

  return (
    <FormControl sx={{ width: '100%' }}>
      <FormLabel>
        Name{' '}
        <Box component="span" sx={{ color: 'bw.red' }}>
          *
        </Box>
      </FormLabel>

      <TextField
        value={payload.reporterName}
        onChange={onChange}
        disabled={loading}
        placeholder="Enter Name *"
        required
        inputProps={{ maxLength: 30 }}
      />
    </FormControl>
  )
}

export default ReporterName
