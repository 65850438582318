import React, { useRef } from 'react'
import { Stack, Typography } from '@mui/material'
import Button from 'src/components/Button'
import { useAppDispatch, useAppSelect } from 'src/hooks/useRedux'

import { sendFraudulentReport } from '../../ducks/report.actions'

import CaptchaInput, { type ICaptchaInputRef } from './CaptchaInput'
import CardBackFile from './CardBackFile'
import CardFrontFile from './CardFrontFile'
import Comment from './Comment'
import ReporterContactInfo from './ReporterContactInfo'
import SchemeInput from './SchemeInput'

const Fraudulent: React.FC = () => {
  const captchaRef = useRef<ICaptchaInputRef>()
  const { payload, loading, message } = useAppSelect((state) => state.reports)
  const dispatch = useAppDispatch()

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()

    if (!payload.captchaToken) {
      alert('Please verify captcha')
      return
    }

    if (!payload.backImage || !payload.frontImage) {
      alert('please select front and back image')
      return
    }

    const formData = new FormData()

    formData.append(
      'files',
      payload?.frontImage?.[0],
      `${payload?.frontImage?.[0].name}`,
    )
    formData.append(
      'files',
      payload?.backImage?.[0],
      `${payload?.backImage?.[0].name}`,
    )
    formData.append('message', `${payload.message}`)
    formData.append('schemeIdentifier', `${payload.schemeIdentifier}`)
    formData.append('reporterName', `${payload.reporterName}`)
    formData.append('reporterEmail', `${payload.reporterEmail}`)
    formData.append('reporterTelephone', `${payload.reporterPhoneNumber}`)
    formData.append('customerName', `${payload.customerName}`)
    formData.append('registrationNumber', `${payload.registrationNumber}`)
    formData.append('cardTypeName', `${payload.cardTypeName}`)

    dispatch(sendFraudulentReport(formData))

    captchaRef.current?.resetCaptcha?.()
  }

  return (
    <Stack
      component="form"
      encType="multipart/form-data"
      onSubmit={onSubmit}
      spacing={50}
      direction="row"
    >
      <Stack component="div" spacing={20} flex={1}>
        <SchemeInput />
        <ReporterContactInfo />
        <Comment />
        <CaptchaInput ref={captchaRef} />

        <Button
          type="submit"
          title={loading ? 'Processing...' : 'Submit'}
          size="large"
          sx={{ width: 152, height: 48 }}
          disabled={loading}
        />

        {message && <Typography>{message}</Typography>}
      </Stack>

      <Stack
        justifyContent="center"
        alignItems="flex-start"
        flex={0.5}
        sx={{ backgroundColor: 'bw.bg', height: 550 }}
        p={15}
        spacing={10}
        borderRadius={(theme) => theme.shape.borderRadius / 8}
      >
        <CardFrontFile />
        <CardBackFile />
      </Stack>
    </Stack>
  )
}

export default Fraudulent
