import React from 'react'
import { Box, CssBaseline } from '@mui/material'
import Header from 'src/components/Header'
import { Profile } from 'src/features/profile'

const ProfilePage: React.FC = () => {
  return (<Box sx={{ backgroundColor: 'bw.white' }}>
    <CssBaseline />
    <Header />
    <Profile />
  </Box>)
}

export default ProfilePage
