interface IAccessToken {
  accessToken: string
  loading: boolean
  error: string
}

export const initialState: IAccessToken = {
  accessToken: '',
  loading: false,
  error: '',
}
