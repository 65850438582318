import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

import { sendFraudulentReport } from './report.actions'
import { initialState, type IReportPayload } from './report.state'

const reportSlice = createSlice({
  name: 'report_slice',
  initialState,
  reducers: {
    setReportPayload: (state, { payload }: PayloadAction<IReportPayload>) => {
      state.payload = payload
    },
  },
  extraReducers(builder) {
    builder.addCase(sendFraudulentReport.pending, (state) => {
      state.loading = true
      state.message = ''
    })
    builder.addCase(sendFraudulentReport.fulfilled, (state) => {
      state.loading = false
      state.message = 'Report submitted'
    })
    builder.addCase(sendFraudulentReport.rejected, (state, { payload }) => {
      state.loading = false
      if (typeof payload === 'string') {
        state.message = payload
      }
    })
  },
})

export const { setReportPayload } = reportSlice.actions

export default reportSlice.reducer
