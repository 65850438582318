import React from 'react'
import { Box, Stack, Typography } from '@mui/material'

import Button from './Button'
import Image from './Image'

const HelpAndSupport: React.FC = () => {
  const onClick = () => {
    window.location.href = 'https://www.cscs.uk.com/'
  }
  return (
    <Box sx={{ width: '100%', position: 'relative' }}>
      <Image
        src="/images/footer-pattern.png"
        sx={{
          width: 288,
          height: 562,
          position: 'absolute',
          left: 0,
          translate: '0 -30%',
        }}
      />
      <Stack
        justifyContent="space-between"
        alignItems="center"
        component="div"
        sx={{
          width: '45%',
          minHeight: '195px',
          margin: (theme) => theme.spacing(30, 'auto'),
        }}
      >
        <Box component="div">
          <Typography
            fontSize={(theme) => theme.typography.fontSize + 10}
            fontWeight={(theme) => theme.typography.fontWeightBold}
            mb={5}
            textAlign="center"
            fontFamily="MetaProBold"
          >
            Help and support
          </Typography>
          <Typography textAlign="center">
            If you experience problems checking a card or require further
            assistance, please contact the appropriate card scheme via the
            corresponding URL listed on our support page.
          </Typography>
        </Box>
        <Button title="Click here" onClick={onClick} sx={{ width: '159px' }} />
      </Stack>
    </Box>
  )
}

export default HelpAndSupport
