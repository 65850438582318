import { createSlice } from '@reduxjs/toolkit'

import { getAccessTokenByRefresh } from './Actions'

const initialState = {
  loading: false,
  error: '',
  payload: {
    responseData: '',
  },
}

const AccessTokenByRefreshReducer = createSlice({
  name: 'accessTokenByRefreshReducer',
  initialState,
  reducers: {
    setPayload: (state, { payload }) => {
      state.payload = payload
    },
    clearError: (state) => {
      state.error = ''
    },
  },
  extraReducers: (builder) => {
      builder.addCase(getAccessTokenByRefresh.pending, (state) => {
      state.loading = true
      state.error = ''
    })
    // Handle fulfilled state
    builder.addCase(getAccessTokenByRefresh.fulfilled, (state, { payload }) => {
      state.loading = false
      state.payload.responseData = payload
    })
    // Handle rejected state
    builder.addCase(getAccessTokenByRefresh.rejected, (state, { payload }: any) => {
      state.loading = false
      state.error = payload?.message || 'Something went wrong'
    })
  },
})

export const { setPayload, clearError } = AccessTokenByRefreshReducer.actions
export default AccessTokenByRefreshReducer.reducer
