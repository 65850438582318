import { createAsyncThunk } from '@reduxjs/toolkit'
import { http } from 'src/libs'
import { NOT_FOUND } from 'src/utils/errorCode'
import Cookies from 'universal-cookie'

export const ProfileAction = createAsyncThunk(
  'profileReducer',
  async (payload: any, thunkApi) => {
    try {
        const cookie = new Cookies()
      const { response, error } = await http({
        method: 'GET',
        baseUrl: process.env.REACT_APP_API_BASE_URL,
        path: {
            url: 'GET_PROFILE',
            },
          authToken: `Bearer ${cookie.get('accessToken')}`,
         })
        if (error) {
          return thunkApi.rejectWithValue(error)
        }
        const data = response?.data
        if (data?.errorCode === NOT_FOUND) {
          return thunkApi.rejectWithValue({ message: 'Not Found!' })
        }

        return thunkApi.fulfillWithValue(data)
        } catch (error) {
            return thunkApi.rejectWithValue(error)
            }
        }
    )
