import React, { useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import PatternIcon from 'src/icons/PatternIcon'

import { FraudulentForm } from './components'

const Report: React.FC = () => {
  // scroll to top
  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: 0 })
  }, [])

  return (
    <Box component="div" sx={{ position: 'relative', width: '100%' }}>
      <PatternIcon
        sx={{
          position: 'absolute',
          width: 340,
          height: 720,
          left: 0,
          top: 0,
          translate: '0 -20%',
          pointerEvents: 'none',
        }}
      />

      <Box
        component="div"
        sx={{
          width: '70%',
          margin: (theme) => theme.spacing(50, 'auto'),
        }}
      >
        <Typography
          textAlign="center"
          fontSize={(theme) => theme.typography.fontSize * 4}
          fontWeight="bold"
          mb={30}
          fontFamily="MetaProBold"
        >
          Report Fraudulent Card
        </Typography>

        {/* {state && <UserCardComponent card={state.card} scheme={scheme} />} */}
        <FraudulentForm />
      </Box>
    </Box>
  )
}

export default Report
