import { type IScheme } from 'src/utils/types/scheme'

interface ISchemeState {
  loading: boolean
  scheme: IScheme[]
  error: string
}

export const initialState: ISchemeState = {
  loading: false,
  error: '',
  scheme: [],
}
