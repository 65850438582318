import React from 'react'
import { SupportPage } from 'src/features'
import BaseLayout from 'src/layouts'

const Support: React.FC = () => {
  return (
    <BaseLayout>
      <SupportPage />
    </BaseLayout>
  )
}

export default Support
