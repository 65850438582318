import { type IHomeState } from './type'

export const initialState: IHomeState = {
  loading: false,
  payload: {
    cardSerialNumber: '',
    schemeName: '',
    schemeIdentifier: '',
    latitude: 0,
    longitude: 0,
    surName: '',
    captchaToken: '',
    scanType: 3,
  },
  expiredOrCancelledCards: [],
  activeCards: [],
  cardsResponseData: {
    cards: [],
    scheme: {
      schemeIdentifier: '',
      schemeName: '',
    },
  },
  error: '',
}
