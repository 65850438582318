import React, { useRef, useState } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import Button from 'src/components/Button'
import Captcha, { type ICaptchaRef } from 'src/components/Captcha'
import LightBackground from 'src/components/LightBackground'
import { useAppDispatch, useAppSelect } from 'src/hooks/useRedux'
import PatternIcon from 'src/icons/PatternIcon'

import { getCard } from '../../ducks/home.actions'
import { setPayload } from '../../ducks/home.reducer'
import TextContent from '../TextContent'

import FormHeader from './FormHeader'
import LastName from './LastName'
import RegistrationNumber from './RegistrationNumber'
import Scheme from './SchemeInput'

const SmartCheckForm: React.FC = () => {
  const [showInfo, setInfoState] = useState<boolean>(() => false)
  const { payload, loading, error } = useAppSelect((state) => state.home)
  const dispatch = useAppDispatch()
  const captchaRef = useRef<ICaptchaRef>(null)

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    if (!payload.captchaToken) {
      alert('Please validate captcha')
      return
    }

    dispatch(getCard(payload))

    resetForm()
  }

  const resetForm = () => {
    captchaRef.current?.resetCaptcha?.()
    dispatch(setPayload({ ...payload, captchaToken: '' }))
  }

  return (
    <LightBackground>
      <FormHeader
        setInfoState={() => {
          setInfoState(true)
        }}
      />
      <Box
        component="div"
        sx={{ position: 'relative', width: '100%', pointerEvents: 'none' }}
      >
        <PatternIcon
          sx={{
            position: 'absolute',
            width: 340,
            height: 720,
            left: 0,
            top: 0,
            translate: '0 -49.7%',
            pointerEvents: 'none',
          }}
        />
      </Box>
      <form onSubmit={onSubmit} style={{ width: '70%', zIndex: 9 }}>
        <Stack justifyContent="center" alignItems="center" mt={10} spacing={10}>
          <Stack direction="row" spacing={10} sx={{
 width: '100%',
            '@media (max-width: 1000px)': {
              width: '100%',
              flexDirection: 'column', // Switch to block layout for small screens
            },
           }}>
            {showInfo ? (
              <TextContent
                onClose={() => {
                  setInfoState(false)
                }}
              />
            ) : (
              <>
                <Scheme />
                <RegistrationNumber />
                <LastName />
              </>
            )}
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
 width: '100%',
            '@media (max-width: 1000px)': {
              width: '100%',
              marginLeft: '47px !important',
              flexDirection: 'column', // Switch to block layout for small screens
            },
            }}
          >
            <Captcha
              ref={captchaRef}
              onChange={(token) => {
                dispatch(setPayload({ ...payload, captchaToken: token || '' }))
              }}
              isLoading={loading}
            />

            <Button
              title={loading ? 'Loading' : 'Check Card'}
              type="submit"
              disabled={loading}
              sx={{
              '@media (max-width: 1000px)': {
                marginRight: 'auto'
               },
              }}
            />
          </Stack>

          {error && <Typography color="error">{error}</Typography>}
        </Stack>
      </form>
    </LightBackground>
  )
}

export default SmartCheckForm
