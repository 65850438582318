import { createSlice } from '@reduxjs/toolkit'

import { getAccessToken } from './accessToken.actions'
import { initialState } from './accessToken.state'

const accessTokenSlice = createSlice({
  name: 'access_token',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getAccessToken.pending, (state) => {
      state.loading = true
      state.error = ''
      state.accessToken = ''
    })
    builder.addCase(getAccessToken.fulfilled, (state, { payload }) => {
      state.loading = false
      state.error = ''
      if (typeof payload === 'string') {
        state.accessToken = payload
      }
    })
    builder.addCase(getAccessToken.rejected, (state, { payload }) => {
      state.loading = false
      state.accessToken = ''
      if (typeof payload === 'string') {
        state.error = payload
      }
    })
  },
})

export default accessTokenSlice.reducer
