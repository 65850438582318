import React from 'react' // Add the missing import statement for React
import { SvgIcon } from '@mui/material'
import PropTypes from 'prop-types' // Add the import statement for PropTypes

const LinkedInIcon: React.FC<any> = (props) => {
  return (
    <SvgIcon width="31" height="31" viewBox="0 0 31 31" fill="none" {...props}>
      <g clipPath="url(#clip0_86_3107)">
        <path
          d="M28.6248 0H2.68628C1.44375 0 0.439026 0.980945 0.439026 2.19375V28.2393C0.439026 29.4521 1.44375 30.439 2.68628 30.439H28.6248C29.8674 30.439 30.878 29.4521 30.878 28.2453V2.19375C30.878 0.980945 29.8674 0 28.6248 0ZM9.46967 25.9386H4.95137V11.4087H9.46967V25.9386ZM7.21052 9.42896C5.75991 9.42896 4.58872 8.25777 4.58872 6.81311C4.58872 5.36844 5.75991 4.19726 7.21052 4.19726C8.65518 4.19726 9.82637 5.36844 9.82637 6.81311C9.82637 8.25183 8.65518 9.42896 7.21052 9.42896ZM26.3776 25.9386H21.8652V18.8758C21.8652 17.1933 21.8355 15.0233 19.5169 15.0233C17.1686 15.0233 16.8119 16.8604 16.8119 18.7569V25.9386H12.3055V11.4087H16.6335V13.3944H16.693C17.2934 12.2529 18.7678 11.046 20.9616 11.046C25.5334 11.046 26.3776 14.0543 26.3776 17.9662V25.9386Z"
          fill={props.color || 'white'}
        />
      </g>
      <defs>
        <clipPath id="clip0_86_3107">
          <rect
            width="30.439"
            height="30.439"
            fill={props.color || 'white'}
            transform="translate(0.439026)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}

LinkedInIcon.propTypes = {
  color: PropTypes.string, // Add prop types validation for the 'color' prop
}

export default LinkedInIcon
