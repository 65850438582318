import { Box, TextField } from '@mui/material'
import { styled } from '@mui/material/styles'
export const Container = styled(Box)({
  width: '100%',
  height: 176.1,
  margin: 'auto',
  display: 'block',
  padding: '0 15rem',
  '@media (max-width: 800px)': {
    padding: '0 5rem',
  }
})
export const Row = styled(Box)({
  width: '100%',
  height: 176.1,
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  gap: 107,
  '@media (max-width: 1100px)': {
    gap: 27,
  },
  '@media (max-width: 900px)': {
    display: 'block'
  }
})

export const Column = styled(Box)({
  width: '100%',
  flexDirection: 'column',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  gap: 20,
  '@media (max-width: 900px)': {
    paddingBottom: '1.3rem !important'
  }
})

export const Label = styled('div')({
  color: '#5A607F',
  fontSize: 20,
  fontWeight: 400,
})

export const StyledTextField = styled(TextField)({
  width: '100%',
  '& .MuiInputBase-root': {
    height: 48,
    background: '#F8F8F8',
    borderRadius: 4,
    border: '1px solid #D9E1EC',
  },
  '& .MuiOutlinedInput-input': {
    padding: '10px 21px',
  },
})

export const Divider = styled(Box)({
  height: 176.1,
  width: 1,
  transform: 'rotate(360deg)',
  transformOrigin: '0 0',
  border: '1px solid #D9E1EC',
  '@media (max-width: 900px)': {
    display: 'none !important'
  }
})
