import React from 'react'
import { SvgIcon } from '@mui/material'

const TwitterXIcon: React.FC<any> = (props) => {
  return (
    <SvgIcon width="31" height="31" viewBox="0 0 31 31" fill="none" {...props} >
      <path
        d="M23.2431 2.41504H27.5217L18.1743 13.0985L29.1707 27.6363H20.5606L13.8168 18.8192L6.10044 27.6363H1.8193L11.8173 16.2091L1.26831 2.41504H10.097L16.1928 10.4742L23.2431 2.41504ZM21.7415 25.0753H24.1123L8.80881 4.84145H6.2647L21.7415 25.0753Z"
        fill={props.color || 'white'}
      />
    </SvgIcon>
  )
}

export default TwitterXIcon
