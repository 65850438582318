import React, { useEffect } from 'react' // If using React < 17, keep this import
import { useNavigate } from 'react-router-dom'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import { IconButton } from '@mui/material'
import { useAppDispatch, useAppSelect } from 'src/hooks/useRedux'

import { ProfileAction } from './StateHandler/Actions'
import { Column, Container, Divider, Label, Row, StyledTextField } from './styles'

const Profile: React.FC = () => {
  const history = useNavigate()
  const state: any = useAppSelect((state) => state.profileReducer)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(ProfileAction({}))
  }, [dispatch])

  const { name, email, company, mobileNumber } = state?.payload || {}
  const loading = state?.loading
  return (
    <div>
      <Container>
        <div>
          <IconButton
             onClick={() => { history('/') }}
             style={{ backgroundColor: '#EFC73B', padding: 10, color: 'white', marginTop: '50px' }}
          >
            <ArrowBackIosNewIcon />
          </IconButton>

          <div
            style={{
              color: '#262626',
              fontSize: 30,
              paddingTop: '4rem',
              fontWeight: '700',
              wordWrap: 'break-word',
              fontFamily: 'MetaProBold',
            }}
          >
            My Profile
          </div>
          <div
            style={{
              color: '#5A607F',
              fontSize: 20,
              fontWeight: '400',
              wordWrap: 'break-word',
              paddingTop: '0.3rem',
              paddingBottom: '3rem',
            }}
          >
            Your profile information is displayed below. This can only be
            updated by your Employer.
          </div>
        </div>
       {loading ? <div>Loading...</div>
        : <Row>
          <Column>
            <div style={{ width: '100%' }}>
              <Label>Name</Label>
              <StyledTextField
                defaultValue={name || ''}
                InputProps={{ readOnly: true }}
              />
            </div>
            <div style={{ width: '100%' }}>
              <Label>Company</Label>
              <StyledTextField
                defaultValue={company || ''}
                InputProps={{ readOnly: true }}
              />
            </div>
          </Column>

          <Divider />

          <Column>
            <div style={{ width: '100%' }}>
              <Label>Email</Label>
              <StyledTextField
                defaultValue={email || ''}
                InputProps={{ readOnly: true }}
              />
            </div>
            <div style={{ width: '100%' }}>
              <Label>Mobile Number</Label>
              <StyledTextField
                defaultValue={mobileNumber || ''}
                InputProps={{ readOnly: true }}
              />
            </div>
          </Column>
        </Row>}
      </Container>
    </div>
  )
}

export default Profile
