import { createSlice } from '@reduxjs/toolkit'

import { LoginAuth } from './Actions'

const initialState = {
  loading: false,
  error: '',
  isOtpSent: false,
  payload: {
    responseData: '',
  },
}

const loginReducer = createSlice({
  name: 'loginReducer',
  initialState,
  reducers: {
    setPayload: (state, { payload }) => {
      state.payload = payload
    },
    clearError: (state) => {
      state.error = ''
    },
    // set as initial
    setAsInitial: (state) => {
      state.payload.responseData = ''
    },
    setOtpSent: (state) => {
      state.isOtpSent = true
    },
    clearOtpStatus: (state) => {
      state.isOtpSent = false
    }
  },
  extraReducers: (builder) => {
    // Handle pending state
    builder.addCase(LoginAuth.pending, (state) => {
      state.loading = true
      state.error = ''
    })
    // Handle fulfilled state
    builder.addCase(LoginAuth.fulfilled, (state, { payload }) => {
      state.loading = false
      state.payload.responseData = payload
    })
    // Handle rejected state
    builder.addCase(LoginAuth.rejected, (state, { payload }: any) => {
      state.loading = false
      state.error = payload?.message || 'Something went wrong'
    })
  },
})

export const { setPayload, clearError, setAsInitial, setOtpSent, clearOtpStatus } = loginReducer.actions
export default loginReducer.reducer
