import React from 'react'
import { SvgIcon } from '@mui/material'

import { type SvgIconType } from './SvgIconType'

const UploadIcon: SvgIconType = (props) => {
  return (
    <SvgIcon viewBox="0 0 31 31" fill="none" {...props}>
      <path
        d="M30.7934 25.3358C30.5621 25.9063 29.9917 26.2917 29.375 26.2917H27.8334V29.375C27.8334 30.2229 27.1396 30.9167 26.2917 30.9167C25.4438 30.9167 24.75 30.2229 24.75 29.375V26.2917H23.2084C22.5917 26.2917 22.0213 25.9217 21.79 25.3358C21.5588 24.75 21.6821 24.1025 22.1292 23.6554L25.2125 20.5721C25.3513 20.4333 25.5209 20.31 25.7213 20.2329C26.0913 20.0788 26.523 20.0788 26.893 20.2329C27.078 20.31 27.2475 20.4179 27.4017 20.5721L30.485 23.6554C30.9321 24.1025 31.0555 24.7654 30.8242 25.3358H30.7934ZM21.6205 17.3192L14.7446 21.4508C14.5134 21.5896 14.2359 21.6667 13.9584 21.6667C13.6655 21.6667 13.3571 21.5742 13.1105 21.4046L9.05587 18.7067L0.083374 21.1579V23.2083C0.083374 25.7521 2.16462 27.8333 4.70837 27.8333H17.0417C17.8896 27.8333 18.5834 27.1396 18.5834 26.2917C18.5834 22.8229 20.865 19.8938 24.01 18.9225L21.6205 17.3192ZM8.93254 15.5617L0.083374 17.9667V4.70834C0.083374 2.16459 2.16462 0.0833435 4.70837 0.0833435H23.2084C25.7521 0.0833435 27.8334 2.16459 27.8334 4.70834V17.0417C27.8334 17.2575 27.7871 17.4733 27.6946 17.6583L22.5146 14.2204C22.0213 13.8813 21.3892 13.8658 20.8805 14.1742L14.0046 18.3058L10.1813 15.7621C9.81129 15.5154 9.36421 15.4383 8.93254 15.5617ZM8.56254 10.875C9.84212 10.875 10.875 9.84209 10.875 8.56251C10.875 7.28293 9.84212 6.25001 8.56254 6.25001C7.28296 6.25001 6.25004 7.28293 6.25004 8.56251C6.25004 9.84209 7.28296 10.875 8.56254 10.875Z"
        fill="#EFC73B"
      />
    </SvgIcon>
  )
}

export default UploadIcon
