import React from 'react'
import InfoIcon from '@mui/icons-material/Info'
import { IconButton, styled, Tooltip, tooltipClasses, type TooltipProps } from '@mui/material'

const IconWrapper = styled('div')({
  width: 24,
  height: 24,
  position: 'relative',
})
const ToolTipBox = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))

const IconCircle = styled('div')({
  width: 20,
  height: 20.01,
  left: 2,
  top: 2,
  position: 'absolute',
//   background: '#0179C1',
  borderRadius: '50%',
})

const PopoverContainer = styled('div')({
  width: 435,
  height: 58,
  paddingLeft: 14,
  paddingRight: 4,
  paddingTop: 2,
  paddingBottom: 2,
  background: '#fefefe',
  top: 3,
  position: 'relative',
  boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgb(201 201 201 / 14%), 0px 1px 3px 0px rgb(206 206 206)',
  borderRadius: 10,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const Text = styled('div')({
  width: 482,
  color: '#2C2C2C',
  fontSize: 16,
  fontFamily: 'calibri',
  fontWeight: '300',
  wordWrap: 'break-word',
})

const InfoComponent: React.FC = () => {
  return (
    <ToolTipBox
     placement="top"
     style={{ backgroundColor: 'none' }}
      title={
         <PopoverContainer >
          <Text>
            CSCS Smart Check provides enhanced functionality if you have a login from your employer. Otherwise you can continue as a guest.
          </Text>
         </PopoverContainer>
      }
      arrow
    >
      <IconButton style={{ padding: 0, paddingBottom: 7 }}>
        <IconWrapper>
          <IconCircle />
          <InfoIcon sx={{ color: 'white', position: 'absolute', top: 2, left: 2, }} style={{ color: '#0179C1' }} />
        </IconWrapper>
      </IconButton>
    </ToolTipBox>
  )
}

export default InfoComponent
