import React from 'react'
import { Box } from '@mui/material'
import Image from 'src/components/Image'

const Support: React.FC = () => {
  return (
    <Box component="div">
      <Image
        src="/images/support.jpg"
        sx={{ width: '100%', height: '50vh', objectFit: 'cover' }}
      />
    </Box>
  )
}

export default Support
