import React from 'react'
import { SvgIcon } from '@mui/material'

import { type SvgIconType } from './SvgIconType'

const PatternIcon2: SvgIconType = (props) => {
  return (
    <SvgIcon viewBox="0 0 309 754" fill="none" {...props}>
      <g clipPath="url(#clip0_198_42030)">
        <mask
          id="mask0_198_42030"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="702"
          height="754"
        >
          <path d="M0 0H702V754H0V0Z" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_198_42030)">
          <mask
            id="mask1_198_42030"
            style={{ maskType: 'luminance' }}
            maskUnits="userSpaceOnUse"
            x="-218"
            y="-195"
            width="1116"
            height="1056"
          >
            <path
              d="M523.218 -194.549L-217.583 343.751L156.631 860.056L897.432 321.756L523.218 -194.549Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask1_198_42030)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M102.419 242.552L138.42 255.381L144.469 294.418L107.975 280.65L102.419 242.552ZM103.52 243.896L108.784 279.998L143.349 293.037L137.617 256.046L103.52 243.896Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M85.6093 296.837L80.0415 258.801L79.1555 258.931L84.7999 297.49L121.753 311.394L122.068 310.555L85.6093 296.837Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M85.2646 296.743L108.084 280.162L108.61 280.887L85.7906 297.469L85.2646 296.743Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M120.988 310.731L144.077 293.953L144.603 294.678L121.513 311.457L120.988 310.731Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M79.0485 259.192L102.286 242.308L102.812 243.033L79.5744 259.918L79.0485 259.192Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M144.485 293.864L180.483 306.695L186.531 345.732L150.041 331.962L144.485 293.864ZM145.586 295.208L150.85 331.309L185.411 344.351L179.68 307.36L145.586 295.208Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M127.675 348.148L122.107 310.119L121.221 310.249L126.867 348.802L163.82 362.705L164.135 361.866L127.675 348.148Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M127.328 348.057L150.147 331.476L150.673 332.201L127.854 348.783L127.328 348.057Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M163.054 362.043L186.144 345.265L186.669 345.991L163.579 362.769L163.054 362.043Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M121.115 310.504L144.352 293.619L144.878 294.344L121.641 311.23L121.115 310.504Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M160.986 239.306L196.984 252.137L203.036 291.171L166.541 277.404L160.986 239.306ZM162.088 240.65L167.352 276.751L201.915 289.79L196.181 252.802L162.088 240.65Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M144.177 293.59L138.612 255.559L137.725 255.689L143.367 294.244L180.322 308.148L180.636 307.308L144.177 293.59Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M143.832 293.497L166.652 276.915L167.178 277.641L144.359 294.223L143.832 293.497Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M179.555 307.485L202.644 290.707L203.171 291.433L180.081 308.211L179.555 307.485Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M137.616 255.947L160.854 239.061L161.379 239.786L138.142 256.672L137.616 255.947Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M202.975 290.885L238.97 303.714L245.024 342.752L208.527 328.981L202.975 290.885ZM204.075 292.229L209.337 328.328L243.904 341.371L238.167 304.379L204.075 292.229Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M186.165 345.171L180.597 307.135L179.711 307.265L185.356 345.824L222.309 359.728L222.624 358.889L186.165 345.171Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M185.82 345.077L208.64 328.496L209.166 329.221L186.346 345.803L185.82 345.077Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M221.543 359.065L244.633 342.287L245.158 343.013L222.068 359.79L221.543 359.065Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M179.604 307.526L202.841 290.642L203.367 291.367L180.13 308.253L179.604 307.526Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M245.041 342.198L281.039 355.029L287.087 394.066L250.596 380.296L245.041 342.198ZM246.141 343.542L251.406 379.643L285.967 392.685L280.236 355.694L246.141 343.542Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M228.232 396.483L222.66 358.449L221.775 358.579L227.422 397.135L264.376 411.039L264.691 410.2L228.232 396.483Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M227.884 396.391L250.704 379.81L251.229 380.535L228.41 397.117L227.884 396.391Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M263.609 410.378L286.699 393.6L287.225 394.325L264.136 411.103L263.609 410.378Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M221.671 358.839L244.905 341.956L245.43 342.681L222.196 359.564L221.671 358.839Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M261.542 287.64L297.54 300.471L303.592 339.505L267.098 325.738L261.542 287.64ZM262.643 288.984L267.907 325.085L302.472 338.124L296.737 301.136L262.643 288.984Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M244.733 341.925L239.165 303.89L238.279 304.02L243.924 342.578L280.877 356.481L281.192 355.642L244.733 341.925Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M244.388 341.831L267.208 325.249L267.733 325.975L244.914 342.557L244.388 341.831Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M280.111 355.819L303.2 339.042L303.726 339.767L280.636 356.545L280.111 355.819Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M238.171 304.281L261.409 287.395L261.935 288.12L238.698 305.006L238.171 304.281Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M176.957 185.133L212.955 197.965L219.006 237L182.513 223.231L176.957 185.133ZM178.058 186.477L183.322 222.579L217.886 235.619L212.152 198.63L178.058 186.477Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M160.147 239.419L154.582 201.386L153.696 201.516L159.338 240.072L196.294 253.979L196.609 253.14L160.147 239.419Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M159.805 239.328L182.625 222.747L183.15 223.472L160.331 240.054L159.805 239.328Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M195.528 253.316L218.618 236.538L219.143 237.263L196.054 254.041L195.528 253.316Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M153.587 201.774L176.824 184.889L177.35 185.614L154.113 202.5L153.587 201.774Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M219.024 236.446L255.021 249.277L261.07 288.314L224.579 274.544L219.024 236.446ZM220.124 237.79L225.389 273.891L259.95 286.932L254.218 249.943L220.124 237.79Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M202.215 290.73L196.646 252.7L195.76 252.83L201.405 291.384L238.36 305.29L238.676 304.451L202.215 290.73Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M201.869 290.642L224.688 274.061L225.214 274.786L202.395 291.368L201.869 290.642Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M237.595 304.628L260.684 287.85L261.21 288.576L238.12 305.354L237.595 304.628Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M195.653 253.086L218.887 236.203L219.414 236.928L196.179 253.811L195.653 253.086Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M235.525 181.888L271.523 194.719L277.577 233.757L241.081 219.986L235.525 181.888ZM236.626 183.232L241.89 219.333L276.456 232.375L270.72 195.384L236.626 183.232Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M218.715 236.172L213.15 198.141L212.264 198.271L217.906 236.825L254.862 250.732L255.177 249.893L218.715 236.172Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M218.373 236.082L241.193 219.5L241.718 220.226L218.899 236.808L218.373 236.082Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M254.095 250.07L277.185 233.292L277.711 234.018L254.622 250.796L254.095 250.07Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M212.157 198.532L235.394 181.646L235.92 182.372L212.683 199.257L212.157 198.532Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M277.513 233.467L313.51 246.299L319.562 285.334L283.068 271.565L277.513 233.467ZM278.613 234.812L283.878 270.914L318.442 283.953L312.708 246.965L278.613 234.812Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M260.704 287.753L255.138 249.72L254.252 249.85L259.894 288.406L296.848 302.309L297.163 301.47L260.704 287.753Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M260.359 287.659L283.178 271.078L283.704 271.803L260.884 288.385L260.359 287.659Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M296.081 301.647L319.171 284.869L319.697 285.594L296.607 302.373L296.081 301.647Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M254.143 250.108L277.38 233.223L277.905 233.949L254.668 250.834L254.143 250.108Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M302.766 339.066L297.201 301.034L296.315 301.164L301.958 339.719L338.911 353.623L339.226 352.784L302.766 339.066Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M302.424 338.976L325.244 322.395L325.769 323.12L302.95 339.702L302.424 338.976Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M296.206 301.422L319.443 284.537L319.969 285.262L296.732 302.148L296.206 301.422Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M151.929 78.874L187.926 91.7057L193.975 130.742L157.484 116.972L151.929 78.874ZM153.03 80.218L158.294 116.32L192.855 129.362L187.123 92.3709L153.03 80.218Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M135.116 133.161L129.55 95.1294L128.665 95.2594L134.307 133.815L171.263 147.721L171.578 146.882L135.116 133.161Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M134.774 133.071L157.594 116.489L158.119 117.215L135.3 133.797L134.774 133.071Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M170.497 147.059L193.586 130.281L194.112 131.007L171.022 147.785L170.497 147.059Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M128.556 95.517L151.792 78.6318L152.319 79.3574L129.081 96.2423L128.556 95.517Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M193.993 130.188L229.99 143.02L236.044 182.057L199.548 168.287L193.993 130.188ZM195.093 131.532L200.357 167.634L234.924 180.676L229.187 143.685L195.093 131.532Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M177.183 184.473L171.615 146.443L170.728 146.573L176.374 185.125L213.329 199.033L213.644 198.194L177.183 184.473Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M176.84 184.382L199.657 167.803L200.183 168.529L177.367 185.108L176.84 184.382Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M212.564 198.37L235.653 181.592L236.179 182.318L213.089 199.096L212.564 198.37Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M170.622 146.828L193.859 129.943L194.385 130.669L171.148 147.553L170.622 146.828Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M210.493 75.6299L246.494 88.4589L252.545 127.499L216.052 113.726L210.493 75.6299ZM211.595 76.9739L216.862 113.073L251.425 126.118L245.692 89.1244L211.595 76.9739Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M193.684 129.915L188.119 91.8828L187.233 92.0128L192.875 130.568L229.831 144.475L230.146 143.635L193.684 129.915Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M193.342 129.825L216.161 113.243L216.687 113.968L193.868 130.55L193.342 129.825Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M229.064 143.813L252.154 127.035L252.68 127.76L229.591 144.539L229.064 143.813Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M187.126 92.274L210.363 75.3887L210.888 76.1142L187.652 92.9993L187.126 92.274Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M252.481 127.21L288.483 140.039L294.531 179.075L258.04 165.305L252.481 127.21ZM253.582 128.554L258.85 164.654L293.411 177.695L287.679 140.705L253.582 128.554Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M235.672 181.495L230.106 143.462L229.221 143.592L234.863 182.148L271.816 196.051L272.132 195.212L235.672 181.495Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M235.329 181.404L258.149 164.823L258.675 165.548L235.856 182.13L235.329 181.404Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M271.05 195.39L294.14 178.612L294.665 179.337L271.576 196.115L271.05 195.39Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M229.111 143.851L252.348 126.965L252.874 127.692L229.637 144.576L229.111 143.851Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M294.548 178.522L330.545 191.354L336.594 230.391L300.103 216.621L294.548 178.522ZM295.649 179.866L300.913 215.968L335.474 229.01L329.743 192.019L295.649 179.866Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M277.739 232.807L272.17 194.777L271.284 194.907L276.929 233.46L313.885 247.367L314.2 246.528L277.739 232.807Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M277.393 232.719L300.212 216.137L300.738 216.863L277.919 233.444L277.393 232.719Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M271.178 195.162L294.415 178.277L294.94 179.003L271.704 195.887L271.178 195.162Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M294.24 178.249L288.674 140.217L287.789 140.347L293.431 178.902L330.386 192.809L330.701 191.969L294.24 178.249Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M293.897 178.158L316.717 161.577L317.243 162.303L294.424 178.885L293.897 178.158Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M287.679 140.604L310.916 123.72L311.442 124.445L288.205 141.33L287.679 140.604Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M226.467 21.4614L262.465 34.2871L268.516 73.327L232.019 59.5562L226.467 21.4614ZM227.567 22.8052L232.829 58.9037L267.396 71.9463L261.662 34.9527L227.567 22.8052Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M209.654 75.7429L204.09 37.7109L203.203 37.8409L208.845 76.3962L245.801 90.3034L246.116 89.4638L209.654 75.7429Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M209.312 75.6525L232.132 59.0708L232.658 59.7965L209.838 76.3782L209.312 75.6525Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M245.035 89.6408L268.125 72.8628L268.65 73.5884L245.561 90.3662L245.035 89.6408Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M203.097 38.102L226.333 21.2168L226.86 21.9424L203.622 38.8277L203.097 38.102Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M268.533 72.7725L304.528 85.6006L310.58 124.64L274.086 110.867L268.533 72.7725ZM269.633 74.1164L274.896 110.215L309.459 123.259L303.725 86.2661L269.633 74.1164Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M251.723 127.057L246.152 89.0244L245.267 89.1545L250.914 127.71L287.867 141.615L288.183 140.775L251.723 127.057Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M251.376 126.966L274.196 110.384L274.721 111.11L251.902 127.692L251.376 126.966Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M287.102 140.952L310.191 124.174L310.717 124.9L287.628 141.678L287.102 140.952Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M245.163 89.413L268.4 72.5278L268.926 73.2534L245.688 90.1384L245.163 89.413Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M285.034 18.2153L321.032 31.0467L327.084 70.0811L290.589 56.3135L285.034 18.2153ZM286.135 19.5594L291.399 55.6609L325.963 68.7004L320.229 31.7122L286.135 19.5594Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M268.225 72.4999L262.657 34.4648L261.771 34.5948L267.415 73.1532L304.369 87.0569L304.684 86.2177L268.225 72.4999Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M267.88 72.4064L290.7 55.8247L291.225 56.5504L268.406 73.1321L267.88 72.4064Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M303.602 86.3947L326.692 69.6167L327.218 70.3424L304.129 87.1203L303.602 86.3947Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M261.664 34.8554L284.901 17.9702L285.428 18.6959L262.19 35.5811L261.664 34.8554Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M310.211 124.076L304.645 86.0444L303.759 86.1744L309.401 124.73L346.357 138.636L346.672 137.797L310.211 124.076Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M303.652 86.4355L326.889 69.5503L327.415 70.2759L304.177 87.1611L303.652 86.4355Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M254.032 503.277L290.03 516.109L296.08 555.149L259.587 541.375L254.032 503.277ZM255.132 504.621L260.396 540.724L294.96 553.768L289.227 516.774L255.132 504.621Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M237.219 557.564L231.654 519.533L230.767 519.663L236.409 558.218L273.365 572.125L273.681 571.286L237.219 557.564Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M236.877 557.475L259.696 540.893L260.222 541.618L237.402 558.2L236.877 557.475Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M272.599 571.463L295.689 554.685L296.214 555.41L273.125 572.188L272.599 571.463Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M230.661 519.924L253.897 503.039L254.424 503.764L231.186 520.649L230.661 519.924Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M296.095 554.592L332.093 567.424L338.146 606.461L301.651 592.69L296.095 554.592ZM297.196 555.936L302.46 592.038L337.026 605.08L331.29 568.089L297.196 555.936Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M279.286 608.877L273.717 570.847L272.831 570.977L278.476 609.529L315.432 623.437L315.747 622.597L279.286 608.877Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M278.943 608.787L301.76 592.208L302.285 592.933L279.469 609.513L278.943 608.787Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M272.727 571.235L295.965 554.351L296.49 555.076L273.253 571.962L272.727 571.235Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M295.789 554.321L290.222 516.287L289.335 516.417L294.98 554.975L331.933 568.879L332.248 568.04L295.789 554.321Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M295.444 554.228L318.264 537.646L318.789 538.373L295.97 554.954L295.444 554.228Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M289.229 516.677L312.465 499.792L312.992 500.518L289.754 517.403L289.229 516.677Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M311.76 500.149L306.192 462.115L305.306 462.245L310.95 500.803L347.904 514.707L348.219 513.867L311.76 500.149Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M305.199 462.506L328.436 445.62L328.962 446.345L305.725 463.231L305.199 462.506Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M303.541 339.605L339.539 352.437L345.587 391.474L309.097 377.704L303.541 339.605ZM304.642 340.949L309.906 377.051L344.467 390.093L338.736 353.102L304.642 340.949Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M286.731 393.89L281.161 355.857L280.274 355.987L285.923 394.544L322.873 408.45L323.188 407.611L286.731 393.89Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M286.384 393.799L309.203 377.218L309.729 377.943L286.909 394.525L286.384 393.799Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M280.168 356.249L303.405 339.364L303.931 340.089L280.693 356.975L280.168 356.249Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3.40231 569.9L39.4001 582.732L45.4517 621.766L8.95768 607.998L3.40231 569.9ZM4.50321 571.244L9.7674 607.346L44.3316 620.385L38.5972 583.397L4.50321 571.244Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M-13.4072 624.185L-18.9758 586.155L-19.8617 586.285L-14.2165 624.838L22.7371 638.742L23.0521 637.903L-13.4072 624.185Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M-13.7518 624.091L9.06774 607.51L9.59361 608.235L-13.2259 624.817L-13.7518 624.091Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M21.9708 638.079L45.0604 621.301L45.5863 622.027L22.4967 638.805L21.9708 638.079Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M-19.9679 586.54L3.26924 569.655L3.79516 570.381L-19.4421 587.266L-19.9679 586.54Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M45.4689 621.212L81.4667 634.043L87.5151 673.08L51.0242 659.31L45.4689 621.212ZM46.5698 622.556L51.834 658.657L86.395 671.699L80.6637 634.709L46.5698 622.556Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M28.6562 675.499L23.0907 637.467L22.2048 637.597L27.8469 676.152L64.8005 690.056L65.1155 689.217L28.6562 675.499Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M28.3139 675.408L51.1334 658.826L51.6593 659.552L28.8398 676.134L28.3139 675.408Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M64.0365 689.396L87.126 672.618L87.652 673.344L64.5624 690.122L64.0365 689.396Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M22.0954 637.854L45.3326 620.97L45.8585 621.695L22.6214 638.58L22.0954 637.854Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M61.9702 566.654L97.9684 579.485L104.02 618.52L67.5255 604.752L61.9702 566.654ZM63.0711 567.998L68.3352 604.099L102.899 617.14L97.1652 580.151L63.0711 567.998Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M45.1607 620.938L39.5921 582.909L38.7061 583.039L44.3513 621.592L81.3073 635.499L81.6223 634.66L45.1607 620.938Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M44.8152 620.85L67.6347 604.269L68.1607 604.995L45.3411 621.576L44.8152 620.85Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M80.541 634.837L103.631 618.059L104.157 618.784L81.0669 635.562L80.541 634.837Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M38.5999 583.295L61.8371 566.409L62.3631 567.135L39.1259 584.02L38.5999 583.295Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M103.958 618.233L139.956 631.065L146.008 670.1L109.514 656.332L103.958 618.233ZM105.059 619.578L110.323 655.68L144.888 668.719L139.153 631.731L105.059 619.578Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M87.1485 672.518L81.5776 634.486L80.6917 634.616L86.3391 673.172L123.292 687.076L123.608 686.237L87.1485 672.518Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M86.8007 672.428L109.62 655.846L110.146 656.572L87.3267 673.153L86.8007 672.428Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M122.527 686.413L145.616 669.635L146.142 670.361L123.052 687.14L122.527 686.413Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M80.5876 634.874L103.825 617.989L104.351 618.715L81.1136 635.6L80.5876 634.874Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M146.021 669.548L182.022 682.377L188.071 721.414L151.577 707.646L146.021 669.548ZM147.122 670.892L152.386 706.994L186.95 720.033L181.219 683.042L147.122 670.892Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M129.212 723.832L123.646 685.801L122.76 685.931L128.402 724.486L165.356 738.39L165.671 737.551L129.212 723.832Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M128.867 723.739L151.687 707.158L152.212 707.883L129.393 724.465L128.867 723.739Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M164.589 737.727L187.679 720.949L188.205 721.675L165.116 738.453L164.589 737.727Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M122.651 686.188L145.888 669.304L146.414 670.029L123.177 686.915L122.651 686.188Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M162.526 614.988L198.524 627.819L204.572 666.856L168.082 653.086L162.526 614.988ZM163.627 616.332L168.891 652.433L203.452 665.475L197.72 628.484L163.627 616.332Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M145.716 669.273L140.148 631.243L139.262 631.373L144.907 669.926L181.861 683.83L182.175 682.99L145.716 669.273Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M145.37 669.184L168.19 652.603L168.716 653.329L145.897 669.91L145.37 669.184Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M181.095 683.168L204.184 666.39L204.71 667.115L181.62 683.893L181.095 683.168Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M139.155 631.629L162.389 614.746L162.915 615.471L139.682 632.354L139.155 631.629Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M77.9407 512.481L113.939 525.313L119.993 564.351L83.496 550.579L77.9407 512.481ZM79.0416 513.826L84.3058 549.928L118.873 562.97L113.136 525.979L79.0416 513.826Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M61.1312 566.767L55.5626 528.737L54.6767 528.867L60.3219 567.419L97.2781 581.327L97.5933 580.488L61.1312 566.767Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M60.7857 566.678L83.6053 550.096L84.1311 550.822L61.3117 567.404L60.7857 566.678Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M96.5116 580.664L119.601 563.886L120.127 564.612L97.0372 581.39L96.5116 580.664Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M54.5705 529.122L77.8077 512.237L78.3335 512.963L55.0964 529.848L54.5705 529.122Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M120.004 563.795L156.005 576.625L162.056 615.665L125.56 601.893L120.004 563.795ZM121.105 565.139L126.369 601.241L160.935 614.284L155.202 577.29L121.105 565.139Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M103.195 618.081L97.6291 580.048L96.7435 580.178L102.385 618.734L139.341 632.641L139.656 631.802L103.195 618.081Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M102.852 617.99L125.671 601.409L126.198 602.134L103.378 618.716L102.852 617.99Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M138.575 631.978L161.664 615.2L162.191 615.926L139.101 632.704L138.575 631.978Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M96.636 580.439L119.874 563.555L120.399 564.28L97.1617 581.165L96.636 580.439Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M136.511 509.239L172.507 522.068L178.557 561.107L142.064 547.334L136.511 509.239ZM137.612 510.583L142.874 546.681L177.437 559.726L171.704 522.733L137.612 510.583Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M119.699 563.52L114.131 525.49L113.244 525.62L118.89 564.174L155.846 578.08L156.161 577.241L119.699 563.52Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M119.353 563.432L142.173 546.851L142.699 547.576L119.879 564.158L119.353 563.432Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M155.079 577.418L178.169 560.641L178.695 561.366L155.605 578.144L155.079 577.418Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M113.14 525.88L136.374 508.997L136.901 509.722L113.667 526.605L113.14 525.88Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M178.496 560.816L214.494 573.647L220.543 612.684L184.052 598.914L178.496 560.816ZM179.597 562.16L184.862 598.262L219.422 611.303L213.691 574.313L179.597 562.16Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M161.687 615.101L156.118 577.071L155.232 577.201L160.877 615.753L197.833 629.661L198.149 628.822L161.687 615.101Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M161.342 615.013L184.161 598.431L184.687 599.156L161.867 615.738L161.342 615.013Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M197.067 628.998L220.157 612.22L220.682 612.946L197.593 629.724L197.067 628.998Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M155.126 577.456L178.36 560.573L178.886 561.299L155.652 578.182L155.126 577.456Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M220.56 612.129L256.558 624.961L262.609 663.996L226.115 650.227L220.56 612.129ZM221.661 613.474L226.925 649.576L261.489 662.615L255.755 625.627L221.661 613.474Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M203.75 666.415L198.185 628.382L197.299 628.512L202.941 667.068L239.897 680.975L240.212 680.136L203.75 666.415Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M203.408 666.324L226.228 649.743L226.753 650.468L203.934 667.05L203.408 666.324Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M239.13 680.312L262.22 663.534L262.746 664.26L239.656 681.038L239.13 680.312Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M197.189 628.77L220.426 611.885L220.953 612.611L197.715 629.496L197.189 628.77Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M237.064 557.57L273.062 570.401L279.113 609.441L242.62 595.668L237.064 557.57ZM238.165 558.914L243.429 595.015L277.993 608.06L272.259 571.066L238.165 558.914Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M220.254 611.854L214.686 573.824L213.8 573.954L219.445 612.508L256.398 626.417L256.714 625.578L220.254 611.854Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M219.91 611.766L242.729 595.185L243.255 595.91L220.435 612.492L219.91 611.766Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M255.632 625.754L278.725 608.975L279.25 609.7L256.158 626.48L255.632 625.754Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M213.693 574.216L236.93 557.331L237.456 558.056L214.219 574.942L213.693 574.216Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M52.9093 406.225L88.9072 419.056L94.9607 458.094L58.4647 444.323L52.9093 406.225ZM54.0102 407.569L59.2744 443.67L93.8406 456.713L88.1042 419.721L54.0102 407.569Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M36.0999 460.509L30.5344 422.478L29.6485 422.608L35.2905 461.163L72.2465 475.069L72.5615 474.23L36.0999 460.509Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M35.7575 460.419L58.577 443.837L59.103 444.563L36.2835 461.145L35.7575 460.419Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M71.4802 474.407L94.5695 457.629L95.096 458.355L72.0061 475.133L71.4802 474.407Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M29.5414 422.869L52.7786 405.983L53.3045 406.708L30.0673 423.594L29.5414 422.869Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M94.976 457.536L130.974 470.368L137.024 509.407L100.531 495.634L94.976 457.536ZM96.0773 458.88L101.341 494.981L135.904 508.026L130.171 471.033L96.0773 458.88Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M78.1666 511.82L72.598 473.791L71.7121 473.921L77.3573 512.474L114.313 526.38L114.628 525.541L78.1666 511.82Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M77.8211 511.733L100.64 495.151L101.167 495.877L78.3471 512.458L77.8211 511.733Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M113.547 525.718L136.637 508.941L137.162 509.666L114.072 526.444L113.547 525.718Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M71.6081 474.18L94.8426 457.297L95.3682 458.022L72.134 474.905L71.6081 474.18Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M111.48 402.981L147.476 415.809L153.529 454.846L117.033 441.076L111.48 402.981ZM112.581 404.325L117.843 440.424L152.409 453.466L146.672 416.475L112.581 404.325Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M94.6698 457.266L89.1023 419.231L88.2164 419.361L93.8603 457.92L130.815 471.824L131.129 470.984L94.6698 457.266Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M94.325 457.173L117.144 440.591L117.671 441.317L94.8515 457.898L94.325 457.173Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M130.048 471.161L153.137 454.383L153.664 455.109L130.574 471.887L130.048 471.161Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M88.1093 419.622L111.347 402.737L111.872 403.463L88.6351 420.347L88.1093 419.622Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M153.465 454.558L189.463 467.39L195.514 506.425L159.02 492.657L153.465 454.558ZM154.565 455.903L159.83 492.004L194.394 505.044L188.66 468.056L154.565 455.903Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M136.655 508.843L131.09 470.811L130.204 470.941L135.846 509.496L172.802 523.403L173.117 522.564L136.655 508.843Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M136.313 508.753L159.133 492.171L159.658 492.896L136.839 509.478L136.313 508.753Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M172.036 522.741L195.126 505.963L195.651 506.689L172.561 523.466L172.036 522.741Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M130.095 471.199L153.332 454.313L153.858 455.039L130.62 471.924L130.095 471.199Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M195.531 505.869L231.529 518.701L237.58 557.741L201.086 543.967L195.531 505.869ZM196.633 507.214L201.897 543.315L236.459 556.36L230.726 519.367L196.633 507.214Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M178.719 560.158L173.153 522.125L172.268 522.255L177.91 560.81L214.866 574.718L215.18 573.878L178.719 560.158Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M178.376 560.067L201.196 543.485L201.722 544.211L178.903 560.792L178.376 560.067Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M214.099 574.055L237.188 557.277L237.715 558.002L214.625 574.78L214.099 574.055Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M172.158 522.513L195.395 505.627L195.921 506.354L172.684 523.238L172.158 522.513Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M212.033 451.312L248.031 464.144L254.085 503.182L217.588 489.411L212.033 451.312ZM213.133 452.656L218.398 488.758L252.964 501.801L247.228 464.809L213.133 452.656Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M195.223 505.597L189.655 467.567L188.769 467.697L194.414 506.25L231.37 520.157L231.685 519.318L195.223 505.597Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M194.881 505.506L217.697 488.927L218.224 489.653L195.407 506.232L194.881 505.506Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M230.603 519.495L253.693 502.717L254.219 503.443L231.129 520.221L230.603 519.495Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M188.664 467.956L211.902 451.07L212.428 451.796L189.191 468.681L188.664 467.956Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M127.451 348.809L163.448 361.64L169.5 400.675L133.006 386.907L127.451 348.809ZM128.551 350.153L133.815 386.254L168.38 399.294L162.645 362.305L128.551 350.153Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M110.64 403.094L105.07 365.062L104.183 365.192L109.831 403.748L146.785 417.651L147.099 416.812L110.64 403.094Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M110.296 403.001L133.115 386.419L133.642 387.144L110.822 403.726L110.296 403.001Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M146.019 416.989L169.108 400.211L169.634 400.936L146.544 417.714L146.019 416.989Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M104.08 365.45L127.317 348.565L127.843 349.29L104.606 366.176L104.08 365.45Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M169.516 400.121L205.514 412.953L211.563 451.989L175.072 438.219L169.516 400.121ZM170.617 401.465L175.881 437.566L210.442 450.609L204.711 413.618L170.617 401.465Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M152.704 454.408L147.136 416.373L146.25 416.503L151.894 455.062L188.848 468.966L189.163 468.126L152.704 454.408Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M152.359 454.315L175.179 437.733L175.704 438.459L152.885 455.04L152.359 454.315Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M188.081 468.303L211.171 451.525L211.697 452.25L188.608 469.028L188.081 468.303Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M146.143 416.764L169.38 399.879L169.906 400.604L146.669 417.489L146.143 416.764Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M186.017 345.563L222.016 358.395L228.068 397.429L191.573 383.662L186.017 345.563ZM187.119 346.907L192.383 383.009L226.947 396.048L221.212 359.06L187.119 346.907Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M169.208 399.848L163.639 361.819L162.754 361.949L168.399 400.501L205.353 414.405L205.668 413.566L169.208 399.848Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M168.861 399.757L191.68 383.175L192.206 383.901L169.386 400.482L168.861 399.757Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M204.586 413.742L227.676 396.965L228.202 397.691L205.112 414.469L204.586 413.742Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M162.647 362.204L185.885 345.318L186.411 346.044L163.174 362.929L162.647 362.204Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M228.006 397.143L264.001 409.971L270.055 449.009L233.558 435.238L228.006 397.143ZM229.106 398.487L234.369 434.585L268.935 447.628L263.198 410.637L229.106 398.487Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M211.194 451.424L205.625 413.395L204.74 413.525L210.384 452.078L247.34 465.984L247.656 465.145L211.194 451.424Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M210.848 451.337L233.668 434.755L234.194 435.48L211.375 452.062L210.848 451.337Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M246.574 465.323L269.664 448.545L270.189 449.27L247.1 466.048L246.574 465.323Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M204.636 413.784L227.872 396.898L228.398 397.624L205.161 414.509L204.636 413.784Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M270.069 448.457L306.068 461.283L312.119 500.323L275.622 486.552L270.069 448.457ZM271.17 449.801L276.431 485.9L310.999 498.942L305.264 461.948L271.17 449.801Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M253.259 502.742L247.691 464.707L246.806 464.837L252.45 503.395L289.404 517.299L289.718 516.46L253.259 502.742Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M252.914 502.648L275.734 486.066L276.26 486.793L253.441 503.374L252.914 502.648Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M288.638 516.637L311.727 499.859L312.253 500.584L289.163 517.362L288.638 516.637Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M246.698 465.098L269.936 448.212L270.462 448.938L247.225 465.823L246.698 465.098Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M286.574 393.897L322.571 406.729L328.619 445.766L292.129 431.996L286.574 393.897ZM287.674 395.241L292.938 431.343L327.499 444.385L321.769 407.394L287.674 395.241Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M269.764 448.182L264.193 410.149L263.307 410.279L268.955 448.835L305.908 462.739L306.223 461.9L269.764 448.182Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M269.416 448.09L292.235 431.508L292.762 432.235L269.942 448.816L269.416 448.09Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M305.142 462.076L328.232 445.298L328.757 446.025L305.668 462.802L305.142 462.076Z"
              fill="black"
              fillOpacity="0.1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M263.203 410.537L286.438 393.654L286.963 394.381L263.729 411.263L263.203 410.537Z"
              fill="black"
              fillOpacity="0.1"
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_198_42030">
          <rect width="702" height="754" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}

export default PatternIcon2
