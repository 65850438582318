import { createAsyncThunk } from '@reduxjs/toolkit'
import { http } from 'src/libs'
import { NOT_FOUND } from 'src/utils/errorCode'
import { type IResponse } from 'src/utils/types/response'
import { type IScheme } from 'src/utils/types/scheme'

interface ISchemeResponse extends IResponse {
  responseData: IScheme[]
}

export const getSchemes = createAsyncThunk(
  'getSchemes',
  async (_, thunkApi) => {
    try {
      const { response, error } = await http({
        method: 'GET',
        path: { url: 'GET_SCHEME' },
      })

      if (error) {
        return thunkApi.rejectWithValue(error)
      }

      const { responseData, errorCode, responseCode, responseMessage } =
        response?.data as ISchemeResponse

      if (responseCode !== '200') {
        return thunkApi.rejectWithValue(responseMessage)
      }

      if (errorCode === NOT_FOUND) {
        return thunkApi.rejectWithValue({ message: 'Not Found!' })
      }

      responseData?.sort?.((a, b) => {
        return a.schemeName < b.schemeName ? -1 : 1
      })

      return thunkApi.fulfillWithValue(responseData)
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  },
)
