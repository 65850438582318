import React, { useState } from 'react'
import { Box, FormControl, FormLabel, TextField } from '@mui/material'
import { useAppDispatch, useAppSelect } from 'src/hooks/useRedux'

import { setReportPayload } from '../../ducks/report.reducer'

const ReporterEmail: React.FC = () => {
  const dispatch = useAppDispatch()
  const { payload, loading } = useAppSelect((state) => state.reports)
  const [emailError, setEmailError] = useState('')

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    if (!emailRegex.test(email)) {
      setEmailError('Please enter a valid email address.')
    } else {
      setEmailError('')
    }

    dispatch(setReportPayload({ ...payload, reporterEmail: email }))
  }

  return (
    <FormControl sx={{ width: '100%' }}>
      <FormLabel>
        Email{' '}
        <Box component="span" sx={{ color: 'bw.red' }}>
          *
        </Box>
      </FormLabel>

      <TextField
        value={payload.reporterEmail}
        onChange={onChange}
        disabled={loading}
        placeholder="Enter Email *"
        required
        error={!!emailError}
        helperText={emailError}
      />
    </FormControl>
  )
}

export default ReporterEmail
