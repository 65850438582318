import React, { useEffect } from 'react'
import HelpAndSupport from 'src/components/HelpAndSupport'
import MobileApp from 'src/components/MobileApp'
import useLocation from 'src/hooks/useLocation'
import { useAppDispatch, useAppSelect } from 'src/hooks/useRedux'

import PageHeader from './components/PageHeader'
import RenderCardSearchResults from './components/RenderCardSearchResults'
import { setPayload } from './ducks/home.reducer'
import { SmartCheckForm } from './components'

const HomePage: React.FC = () => {
  const { payload } = useAppSelect((state) => state.home)
  const dispatch = useAppDispatch()
  const { location } = useLocation()

  // Set location on load page for the first time.
  useEffect(() => {
    if (!location?.latitude || !location.longitude) return

    if (!payload.longitude || !payload.latitude) {
      dispatch(setPayload({ ...payload, ...location }))
    }
  }, [location])

  return (
    <>
      <PageHeader />
      <SmartCheckForm />
      <RenderCardSearchResults />
      <MobileApp />
      <HelpAndSupport />
    </>
  )
}

export default HomePage
