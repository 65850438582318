import React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { useAppSelect } from 'src/hooks/useRedux'

import Carousel from '../Carousel'
import RenderCardDetails from '../RenderCardDetails'

const RenderCardSearchResults: React.FC = () => {
  const { activeCards } = useAppSelect((state) => state.home)

  return (
    <>
      {activeCards.length ? (
        <Box
          sx={{ width: '70%', margin: (theme) => theme.spacing(20, 'auto') }}
        >
          <Typography
            fontSize={(theme) => theme.typography.fontSize * 2}
            fontWeight="bold"
            mb={10}
          >
            Active Cards
          </Typography>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            flexWrap="wrap"
            rowGap={5}
            columnGap={5}
            sx={{ width: '100%' }}
          >
            {activeCards.length ? (
              activeCards.map((card, index) => (
                <RenderCardDetails key={card.cardSerial + index} card={card} />
              ))
            ) : (
              <></>
            )}
          </Stack>
        </Box>
      ) : (
        <></>
      )}
      <Carousel title="Expired & Cancelled Cards" />
    </>
  )
}

export default RenderCardSearchResults
