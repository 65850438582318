import React, { useState } from 'react'
import { Stack, Typography } from '@mui/material'
import LinkButton from 'src/components/LinkButton'

import { type IOccpuationQualification } from '../../ducks/type'

interface IRenderItemProps {
  label: string
  value: IOccpuationQualification[]
}

const RenderQualifications: React.FC<IRenderItemProps> = ({ label, value }) => {
  const [status, setStatus] = useState<'see less' | 'see more'>('see more')

  const calculateValues = () => {
    if (status === 'see less') {
      return value
    }
    return value.slice(0, 1)
  }

  // Calculate values directly in the component
  const valuesState = calculateValues()

  return (
    <Stack flex="1" justifyContent="flex-start" alignItems="flex-start">
      <Typography
        fontSize={(theme) => theme.typography.fontSize + 1}
        fontWeight="bold"
      >
        {label}
      </Typography>
      {valuesState.map((v, i) => (
        <Typography
          key={v.qual + i}
          fontSize={(theme) => theme.typography.fontSize + 1}
        >
          {i + 1}- {v.qual}
        </Typography>
      ))}
      {value.length > 1 && (
        <LinkButton
          onClick={() => {
            setStatus((pre) => (pre === 'see more' ? 'see less' : 'see more'))
          }}
        >
          {status}
        </LinkButton>
      )}
    </Stack>
  )
}

export default RenderQualifications
