import React from 'react'
import { SvgIcon } from '@mui/material'

import { type SvgIconType } from './SvgIconType'

const DownArrowIcon: SvgIconType = (props) => {
  return (
    <SvgIcon viewBox="0 0 12 7" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.292893 0.292893C-0.0675907 0.653377 -0.0953203 1.22061 0.209705 1.6129L0.292893 1.70711L5.29289 6.70711C5.65338 7.06759 6.22061 7.09532 6.6129 6.7903L6.70711 6.70711L11.7071 1.70711C12.0976 1.31658 12.0976 0.683418 11.7071 0.292893C11.3466 -0.0675907 10.7794 -0.0953203 10.3871 0.209705L10.2929 0.292893L6 4.585L1.70711 0.292893C1.34662 -0.0675907 0.779392 -0.0953203 0.387101 0.209705L0.292893 0.292893Z"
        fill="#7E84A3"
      />
    </SvgIcon>
  )
}

export default DownArrowIcon
