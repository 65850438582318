import React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import LinkedInIcon from 'src/icons/LinkedInIcon'
import TwitterXIcon from 'src/icons/TwitterXIcon'
import YoutubeIcon from 'src/icons/YoutubeIcon'

import Image from './Image'
import Link from './Link'

const Footer = ({ IsLoginPage = false, width, bgColor = 'bw.yellow', iconColor = 'info' }: { IsLoginPage?: boolean, width?: string, bgColor?: string, iconColor?: 'info' | 'primary' | 'secondary' }) => {
  return (
    <>
      {!IsLoginPage && <Box
        component="div"
        sx={{
          background: (theme) => theme.palette.bw.bg,
          height: 124,
        }}
      >
        <Stack
          component="div"
          alignItems="center"
          direction="row"
          spacing={10}
          sx={{
            height: '100%',
            width: '70%',
            margin: (theme) => theme.spacing(0, 'auto'),
          }}
        >
          <Link
            to="https://www.cscs.uk.com/"
            sx={{ height: 79, width: 183, zIndex: 9 }}
          >
            <Image src="/images/logos/logo@2x1.png" />
          </Link>

          <Typography fontSize={(theme) => theme.typography.fontSize - 2}>
            Construction Skills Certification Scheme Ltd - registered in England
            and Wales: 03024675. 71 Queen Victoria Street, London, EC4V 4AY
          </Typography>
        </Stack>
      </Box>}

      <Box component="div" sx={{ backgroundColor: bgColor, height: 59, width }}>
        <Stack
          direction="row"
          spacing={10}
          sx={{
            height: '100%',
            width: width || '70%',
            margin: (theme) => theme.spacing(0, 'auto'),
          }}
          alignItems="center"
        >
          <Stack flex="1" direction="row" alignItems="center" gap={12}>
            <Link
              sx={{
                padding: 0,
                margin: 0,
                fontSize: '17px',
                fontWeight: '500',
                color: '#5A607F',
                height: 'fit-content',
                fontFamily: 'calibri',
                '&: after': {
                  content: '""',
                  display: 'block',
                  width: '0',
                  height: '1px',
                  background: '#000',
                  transition: 'width .3s',
                },
                ':hover::after': {
                  width: '100%',
                },
              }}
              to="https://www.cscs.uk.com/faqs/?faq_category=1005"
            >
              FAQs
            </Link>
            <Link
              sx={{
                padding: 0,
                margin: 0,
                fontSize: '17px',
                fontWeight: '500',
                color: '#5A607F',
                height: 'fit-content',
                fontFamily: 'calibri',
                '&: after': {
                  content: '""',
                  display: 'block',
                  width: '0',
                  height: '1px',
                  background: '#000',
                  transition: 'width .3s',
                },
                ':hover::after': {
                  width: '100%',
                },
              }}
              to="https://www.cscs.uk.com/about/contact/"
            >
              Contact CSCS
            </Link>
          </Stack>

          <Link
            to="https://twitter.com/cscs"
            target="_blank"
            sx={{
              width: 20,
              height: 20,
            }}
          >
            <TwitterXIcon width={32} height={32} color={iconColor} />
          </Link>
          <Link
            to="https://www.linkedin.com/company/construction-skills-certification-scheme-limited"
            target="_blank"
            sx={{
              width: 20,
              height: 20,
            }}
          >
            <LinkedInIcon width={32} height={32} color={iconColor} />
          </Link>
          <Link
            to="https://www.youtube.com/@officialcscs"
            target="_blank"
            sx={{
              width: 20,
              height: 20,
            }}
          >
            <YoutubeIcon width={32} height={32} color={iconColor} />
          </Link>
        </Stack>
      </Box>
    </>
  )
}

export default Footer
