import { createSlice } from '@reduxjs/toolkit'

import { VerifyToken } from './Actions'

const initialState = {
  loading: false,
  error: '',
  payload: {
    responseData: '',
  },
}

const otpReducer = createSlice({
  name: 'otpReducer',
  initialState,
  reducers: {
    setPayload: (state, { payload }) => {
      state.payload = payload
    },
    clearError: (state) => {
      state.error = ''
    },
    setAsInitial: (state) => {
      state.error = ''
      state.loading = false
      state.payload = {
        responseData: '',
      }
    }
  },
  extraReducers: (builder) => {
    console.log(VerifyToken, 'VerifyToken')
    // Handle pending state
    builder.addCase(VerifyToken.pending, (state) => {
      state.loading = true
      state.error = ''
    })
    // Handle fulfilled state
    builder.addCase(VerifyToken.fulfilled, (state, { payload }) => {
      console.log(payload, state, 'VerifyToken')

      state.loading = false
      state.payload.responseData = payload
    })
    // Handle rejected state
    builder.addCase(VerifyToken.rejected, (state, { payload }: any) => {
      state.loading = false
      state.error = payload?.message || 'Something went wrong'
    })
  },
})

export const { setPayload, clearError, setAsInitial } = otpReducer.actions
export default otpReducer.reducer
