import { styled, TextField } from '@mui/material'
import type { TextFieldProps } from '@mui/material/TextField'

const Input = styled(TextField)<TextFieldProps>(({ theme }) => ({
  minWidth: '100%',
  minHeight: '48px',
  '.MuiOutlinedInput-root': {
    backgroundColor: theme.palette.bw.white,
  },
}))

export default Input
