import React from 'react'
import { FormControl, FormLabel } from '@mui/material'
import Input from 'src/components/Input'
import { useAppDispatch, useAppSelect } from 'src/hooks/useRedux'

import { setPayload } from '../../ducks/home.reducer'

const LastName: React.FC = () => {
  const { payload } = useAppSelect((state) => state.home)
  const dispatch = useAppDispatch()

  return (
    <FormControl sx={{ width: '100%' }}>
      <FormLabel
        sx={{
          fontSize: (theme) => theme.typography.fontSize + 2,
          fontWeight: (theme) => theme.typography.fontWeightRegular,
        }}
      >
        Last name<span style={{ color: '#F90000' }}>*</span>
      </FormLabel>
      <Input
        placeholder="Enter last name"
        onChange={(e) =>
          dispatch(setPayload({ ...payload, surName: e.target.value }))
        }
        value={payload.surName}
        required
      />
    </FormControl>
  )
}

export default LastName
