import React from 'react'
import { ReportPage } from 'src/features'
import BaseLayout from 'src/layouts'

const Report: React.FC = () => {
  return (
    <BaseLayout>
      <ReportPage />
    </BaseLayout>
  )
}

export default Report
