import { useEffect, useState } from 'react'
import { getLocation, type IPosition } from 'src/utils/getLocation'

const useLocation = () => {
  const [location, setLocation] = useState<IPosition>({
    longitude: 0,
    latitude: 0,
  })
  const position = async () => {
    try {
      const pos: IPosition = await getLocation()
      setLocation(pos)
    } catch (error) {
      alert(error)
    }
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      position()
    }, 1000)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  return {
    location,
  }
}

export default useLocation
