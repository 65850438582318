import { createSlice } from '@reduxjs/toolkit'

import { getSchemes } from './scheme.actions'
import { initialState } from './scheme.state'

const schemeReducer = createSlice({
  name: 'schemeReducer',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getSchemes.pending, (state) => {
      state.loading = true
      state.error = ''
      state.scheme = []
    })
    builder.addCase(getSchemes.fulfilled, (state, { payload }) => {
      state.loading = false
      state.error = ''
      state.scheme = payload
    })
    builder.addCase(getSchemes.rejected, (state, { payload }) => {
      state.loading = false
      state.scheme = []
      if (typeof payload === 'string') {
        state.error = payload
      }
    })
  },
})

export default schemeReducer.reducer
