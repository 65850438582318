import React from 'react'
import { SvgIcon } from '@mui/material'

const YoutubeIcon: React.FC<any> = (props) => {
  return (
    <SvgIcon width="31" height="31" viewBox="0 0 31 31" fill="none" {...props}>
      <g clipPath="url(#clip0_86_3106)">
        <path
          d="M31.0139 9.13206C31.0139 9.13206 30.7166 7.03343 29.8011 6.11193C28.6418 4.89913 27.3457 4.89318 26.7512 4.82184C22.4945 4.5127 16.1035 4.5127 16.1035 4.5127H16.0916C16.0916 4.5127 9.70061 4.5127 5.44391 4.82184C4.84939 4.89318 3.55336 4.89913 2.39406 6.11193C1.47851 7.03343 1.1872 9.13206 1.1872 9.13206C1.1872 9.13206 0.878052 11.5993 0.878052 14.0606V16.3673C0.878052 18.8285 1.18125 21.2958 1.18125 21.2958C1.18125 21.2958 1.47851 23.3944 2.38811 24.3159C3.54741 25.5287 5.06936 25.4871 5.74711 25.6179C8.18461 25.8497 16.0976 25.9211 16.0976 25.9211C16.0976 25.9211 22.4945 25.9092 26.7512 25.606C27.3457 25.5346 28.6418 25.5287 29.8011 24.3159C30.7166 23.3944 31.0139 21.2958 31.0139 21.2958C31.0139 21.2958 31.3171 18.8345 31.3171 16.3673V14.0606C31.3171 11.5993 31.0139 9.13206 31.0139 9.13206ZM12.9526 19.1674V10.6124L21.1747 14.9048L12.9526 19.1674Z"
          fill={props.color || 'white'}
        />
      </g>
      <defs>
        <clipPath id="clip0_86_3106">
          <rect
            width="30.439"
            height="30.439"
            fill={props.color || 'white'}
            transform="translate(0.878052)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}

export default YoutubeIcon
