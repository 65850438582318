import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import Link from 'src/components/Link'

export const StyledContainer = styled('div')({
  height: '100vh',
  width: '80% !important',
  maxWidth: '100% !important',
  background: '#E4F1F8',
  paddingBottom: '35px',
  border: 'none',
  margin: 'auto',
  boxShadow: 'none',
  backgroundColor: '#E4F1F8 !important',
  paddingLeft: '76px'
})
export const StyledBox = styled('div')({
  display: 'flex',
  width: '100% !important',
  maxWidth: '100% !important',
  alignItems: 'center',
  paddingTop: '4rem',
  justifyContent: 'center',
  border: 'none',
  boxShadow: 'none',
  backgroundColor: '#E4F1F8 !important'
})
export const StyledPaper = styled('div')({
  display: 'flex',
  width: '100%',
})

export const FormSection = styled('div')({
  width: '40%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '@media (max-width: 800px)': {
    width: '100%',
  }
})

export const ImageSection = styled('div')({
  backgroundPosition: 'center',
  paddingTop: '2rem',
  width: '60%',
  '@media (max-width: 800px)': {
    display: 'none'
  }
})
export const PageTitle = styled(Typography)({
  color: '#262626',
  fontSize: '2.25rem',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
  paddingTop: '30px',
  fontFamily: 'MetaProBold'
})
export const StyleSubTitle = styled(Typography)({
  color: '#858585',
  fontSize: '1.25rem',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  paddingTop: '6px',
  paddingBottom: '30px'
})
export const Text = styled(Typography)({
  color: '#858585',
  fontSize: '1rem',
  fontWeight: 400,
  paddingRight: '5px',
  lineHeight: '24px',
  wordWrap: 'break-word'
})

export const LinkText = styled(Link)({
  color: '#0179C1',
  fontSize: '1rem',
  fontWeight: 400,
  textDecoration: 'underline',
  lineHeight: '24px',
  wordWrap: 'break-word',
  cursor: 'pointer',
  fontFamily: 'Calibri'
})
export const LinkTextDiv = styled('div')({
color: '#0179C1',
fontSize: '1rem',
fontWeight: 400,
textDecoration: 'underline',
lineHeight: '24px',
wordWrap: 'break-word',
cursor: 'pointer',
fontFamily: 'Calibri'
})
