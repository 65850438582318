import React from 'react'
import Close from '@mui/icons-material/Close'
import { IconButton, Stack, Typography } from '@mui/material'

interface ITextContentProps {
  onClose?: () => void
}

const TextContent: React.FC<ITextContentProps> = ({ onClose }) => {
  return (
    <Stack
      spacing={10}
      flex="1"
      alignItems="center"
      sx={{
        backgroundColor: 'bw.white',
        border: '1px solid',
        borderColor: 'bw.greyLightest',
        borderRadius: (theme) => theme.shape.borderRadius / 8,
        padding: (theme) => theme.spacing(10),
        position: 'relative',
      }}
    >
      <IconButton
        onClick={onClose}
        sx={{ position: 'absolute', right: 0, top: 0 }}
      >
        <Close />
      </IconButton>
      <Typography textAlign="center">
        Select the scheme the card belongs to (e.g CSCS), then enter the card
        registration number and the individual’s last name and click verify. If
        the check is successful, the cardholder’s information will be displayed.
      </Typography>
      <Typography textAlign="center">
        If the card does not validate, do not allow the individual access to
        site and proceed to contact the relevant scheme for further information
        and verification.
      </Typography>
    </Stack>
  )
}

export default TextContent
