import React from 'react'
import { Box, Stack } from '@mui/material'
import { type ICard } from 'src/utils/types/card'

interface ICarouselDotProps {
  cards: ICard[][]
  active: number
}

const CarouselDots: React.FC<ICarouselDotProps> = ({ cards, active }) => {
  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={5}
      mt={10}
    >
      {cards.map((card, key) => (
        <Box
          component="span"
          key={key}
          sx={{
            width: 10,
            height: 10,
            borderRadius: (theme) => theme.shape.borderRadius,
            backgroundColor:
              active === key ? 'primary.main' : 'bw.greyLightest',
          }}
        ></Box>
      ))}
    </Stack>
  )
}

export default CarouselDots
