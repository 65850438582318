import { Buffer } from 'buffer'

export const hexToBase64 = (hex: string) => {
  if (!hex) {
    return ''
  }

  const base64 = Buffer.from(hex, 'hex').toString('base64')

  return `data:image/png;base64, ${base64}`
}
