import React, { useEffect } from 'react'
import { Box, CssBaseline } from '@mui/material'
import Footer from 'src/components/Footer'
import Header from 'src/components/Header'
import { useAppDispatch } from 'src/hooks/useRedux'
import { getAccessToken } from 'src/libs/redux/accessToken/accessToken.actions'

interface ILayoutProps {
  children: React.ReactNode
}

const BaseLayout: React.FC<ILayoutProps> = ({ children }) => {
  const dispatch = useAppDispatch()

  // fetch access token
  useEffect(() => {
    dispatch(getAccessToken())
  }, [])

  return (
    <Box sx={{ backgroundColor: 'bw.white' }}>
      <CssBaseline />
      <Header />
      {children}
      <Footer />
    </Box>
  )
}

export default BaseLayout
