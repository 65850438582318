import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Box, Button, Stack, Typography } from '@mui/material'

import Header from './Header'
import Image from './Image'

interface IAppErrorBoundryProps {
  children: React.ReactNode
}

const AppFallbackRender: React.FC = () => {
  return (
    <>
      <Header />
      <Stack
        sx={{ minHeight: '80dvh' }}
        justifyContent="center"
        alignItems="center"
        spacing={10}
      >
        <Image src="/images/500.jpg" sx={{ width: '70%' }} />
        <Button
          onClick={() => {
            window.location.reload()
          }}
          variant="outlined"
          size="large"
        >
          Reload Page
        </Button>

        <Typography component="span" fontSize="10" textAlign="right">
          <a href="http://www.freepik.com" target="_blank" rel="noreferrer">
            Designed by stories / Freepik
          </a>
        </Typography>
      </Stack>
    </>
  )
}

const AppErrorBoundry: React.FC<IAppErrorBoundryProps> = ({ children }) => {
  const onError = (error: Error) => {
    return error
  }

  return (
    <ErrorBoundary onError={onError} fallbackRender={AppFallbackRender}>
      <Box>{children}</Box>
    </ErrorBoundary>
  )
}

export default AppErrorBoundry
