import React, { useEffect } from 'react'
import { Autocomplete } from '@mui/material'
import { useAppDispatch, useAppSelect } from 'src/hooks/useRedux'
import DownArrowIcon from 'src/icons/DownArrowIcon'
import { getSchemes } from 'src/libs/redux/scheme/scheme.actions'
import { type IScheme } from 'src/utils/types/scheme'

import Input from '../Input'

interface ISchemeProps {
  onChange: (value: IScheme | null) => void
  value: IScheme
}

const Scheme: React.FC<ISchemeProps> = ({ onChange, value }) => {
  const dispatch = useAppDispatch()
  const { scheme = [], loading } = useAppSelect((state) => state.schemes)
  const { accessToken } = useAppSelect((state) => state.access_token)

  useEffect(() => {
    if (accessToken) {
      dispatch(getSchemes())
    }
  }, [accessToken])

  return (
    <Autocomplete
      options={scheme}
      getOptionLabel={(option) => option.schemeName}
      value={value}
      onChange={(_, value) => {
        onChange(value)
      }}
      loading={loading}
      clearIcon={null}
      sx={{
        '& .MuiAutocomplete-endAdornment': {
          right: '15px !important',
          top: 17,
        },
      }}
      popupIcon={
        <DownArrowIcon
          sx={{
            width: '15px',
            height: '8px',
          }}
        />
      }
      renderInput={(params) => (
        <Input {...params} placeholder="Select" required />
      )}
    />
  )
}

export default Scheme
