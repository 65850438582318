import Cookies from 'universal-cookie'

export const isLogin = () => {
  const cookies = new Cookies()
  const token = cookies.get('accessToken')
  return Boolean(token)
}
export const logOut = () => {
const cookies = document.cookie.split(';')
    cookies.forEach(cookie => {
      const cookieName = cookie.split('=')[0].trim()
      // Set each cookie's expiration date to the past to delete it
      document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;`
    })
    // Redirect user after logout
    window.location.href = '/login'
}
