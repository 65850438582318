/* eslint-disable react/display-name */
import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import Captcha, { type ICaptchaRef } from 'src/components/Captcha'
import { useAppDispatch, useAppSelect } from 'src/hooks/useRedux'

import { setReportPayload } from '../../ducks/report.reducer'

export interface ICaptchaInputRef {
  resetCaptcha: () => void
}

interface ICaptchaInput {
  ref?: any
}

const CaptchaInput: React.FC<ICaptchaInput> = forwardRef((_, ref) => {
  const captchaRef = useRef<ICaptchaRef>()
  const dispatch = useAppDispatch()
  const { payload } = useAppSelect((state) => state.reports)

  useImperativeHandle(ref, () => ({
    resetCaptcha: () => {
      if (!captchaRef) return null

      captchaRef.current?.resetCaptcha?.()
      dispatch(setReportPayload({ ...payload, captchaToken: '' }))
    },
  }))

  return (
    <Captcha
      ref={captchaRef}
      onChange={(token) => {
        dispatch(setReportPayload({ ...payload, captchaToken: token || '' }))
      }}
    />
  )
})

export default CaptchaInput
