import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from '@mui/material'
import { setReportPayload } from 'src/features/report/ducks/report.reducer'
import { useAppDispatch, useAppSelect } from 'src/hooks/useRedux'
import { type IScheme } from 'src/utils/types/scheme'

import { type ICard } from '../../ducks/type'

interface RenderCardFooterProps {
  card: ICard
  scheme: IScheme
}

const RenderCardFooter: React.FC<RenderCardFooterProps> = ({
  card,
  scheme,
}) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { payload: reportPayload } = useAppSelect((state) => state.reports)
  return (
    <Button
      variant="outlined"
      color="error"
      onClick={() => {
        dispatch(
          setReportPayload({
            ...reportPayload,
            message: `Report card # ${card.registrationNumber}`,
            schemeIdentifier: scheme.schemeIdentifier,
            customerName: card.customerName,
            registrationNumber: card.registrationNumber,
            cardTypeName: card.cardTypeName,
          }),
        )
        navigate('/report-card', {
          state: {
            card,
          },
        })
      }}
      fullWidth
      sx={{
        display: 'block',
        textAlign: 'center',
        borderRadius: (theme) => theme.shape.borderRadius / 8,
        padding: (theme) => theme.spacing(3, 0),
        margin: (theme) => theme.spacing(3, 0),
        textTransform: 'none',
        height: '48px',
      }}
    >
      Click here to report a suspected fraudulent card
    </Button>
  )
}

export default RenderCardFooter
