import React from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import { Box, IconButton } from '@mui/material'
import Image from 'src/components/Image'

interface IImagePreviewProps {
  src: string
  onDelete?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const ImagePreview: React.FC<IImagePreviewProps> = ({ src, onDelete }) => {
  return (
    <Box
      component="div"
      sx={{
        position: 'relative',
        border: '1px solid',
        borderColor: 'primary.main',
        borderRadius: (theme) => theme.shape.borderRadius / 7,
        ':hover': { '& > button': { display: 'block' }, cursor: 'pointer' },
        width: '100%',
        height: '183px',
        overflow: 'hidden',
      }}
    >
      <IconButton
        onClick={onDelete}
        color="error"
        sx={{
          position: 'absolute',
          right: 5,
          display: 'none',
          width: 40,
          height: 40,
        }}
      >
        <DeleteIcon />
      </IconButton>
      <Image sx={{ width: '100%' }} src={src} />
    </Box>
  )
}

export default ImagePreview
