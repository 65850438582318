import React from 'react'
import { Typography } from '@mui/material'
import BaseLayout from 'src/layouts'

const NotFoundPage: React.FC = () => {
  return (
    <BaseLayout>
      <Typography>Page Not Found!</Typography>
    </BaseLayout>
  )
}

export default NotFoundPage
