import React from 'react'
import { Stack, Typography } from '@mui/material'

interface IRenderItemProps {
  label: string
  value: string
}

const RenderItem: React.FC<IRenderItemProps> = ({ label, value }) => {
  return (
    <Stack flex="1" justifyContent="flex-start" alignItems="flex-start">
      <Typography
        fontSize={(theme) => theme.typography.fontSize + 1}
        fontWeight="bold"
      >
        {label}
      </Typography>
      <Typography fontSize={(theme) => theme.typography.fontSize + 1}>
        {value}
      </Typography>
    </Stack>
  )
}

export default RenderItem
